import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

/**
 * Dialog used to `Remove` a comment
 * @param {Object} props
 * @param {boolean} props.open - Boolean that represents the state
 * variable of the dialog
 * @param {Function} props.close - Function used for closing the dialog
 * @param {any} props.content - Any content to be displayed inside
 * the Dialog
 * @param {any} props.title - Title for the Dialog
 */
export function FeedbackDialog({ open, close, content, title }) {
  return (
    <Dialog fullWidth onClose={close} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {content ? (
        <>
          <DialogContent>
            <DialogContentText>{content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>Close</Button>
          </DialogActions>
        </>
      ) : (
        <Skeleton
          height={200}
          sx={{ bgcolor: '#6a329f' }}
          variant="rectangular"
        />
      )}
    </Dialog>
  );
}
