import React, { useState, useEffect } from 'react';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { getAbgIntegrationSchema } from '_services/ApiService';
import FullScreenLoader from 'components/Loader';

const AbgIntegrationForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [schema, setSchema] = useState(null);
  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    async function loadSchema() {
      setSchema(await getAbgIntegrationSchema());
      setLoading(false);
    }
    loadSchema();
  }, []);

  const onSubmit = async (v) => {
    setLoading(true);
    try {
      await props.onSubmit(v.formData);
    } catch (e) {
      console.error(e);
      alert(e && e.toString());
    } finally {
      setLoading(false);
    }
  };

  const uiSchema = {};

  return (
    <>
      {loading && <FullScreenLoader />}
      {schema && (
        <Form
          formData={formData}
          liveValidate
          onChange={(v) => setFormData(v.formData)}
          onSubmit={onSubmit}
          schema={schema}
          uiSchema={uiSchema}
          validator={validator}
        />
      )}
    </>
  );
};
AbgIntegrationForm.defaultProps = {
  initialFormData: {}
};

export default AbgIntegrationForm;
