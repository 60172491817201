import React, { useState, useEffect } from 'react';
import {
  getEchecksPayroll,
  setEchecksPayrollPendingAction
} from '../../_services/ApiService';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';

function ExecutionRow({ status, startDate, message, onClick }) {
  const backgroundColor =
    {
      info: 'yellow',
      success: 'green',
      error: 'red'
    }[status] || undefined;
  return (
    <TableRow hover onClick={onClick}>
      <TableCell>{startDate}</TableCell>
      <TableCell style={{ backgroundColor }}>{message}</TableCell>
    </TableRow>
  );
}

const PayrollDescription = ({
  payroll,
  setPendingDryRun,
  setPendingUpload
}) => {
  return (
    <Card style={{ margin: '15px' }}>
      <CardHeader title="Payroll" />
      {payroll && (
        <ul>
          <li>
            Company: {payroll.company.shortName} ({payroll.company.id})
          </li>
          <li>
            Payroll: {payroll.payroll.name} ({payroll.payrollId})
          </li>
          <li>Finalized on: {payroll.payroll.finalizedDate}</li>
          <li>
            EIN: {payroll.payroll.ein.number} - {payroll.payroll.ein.name}
          </li>
          <li>
            Pending action:{' '}
            {payroll.status.pendingAction ? (
              <b>{payroll.status.pendingAction}</b>
            ) : (
              <span style={{ color: '#444' }}>No pending action</span>
            )}{' '}
            <Button
              color="secondary"
              onClick={() => setPendingDryRun()}
              variant="outlined"
            >
              Start dry run upload
            </Button>
            <Button
              color="secondary"
              onClick={() => setPendingUpload()}
              variant="outlined"
            >
              Start upload
            </Button>
          </li>
        </ul>
      )}
    </Card>
  );
};

const PayrollDetails = ({
  payroll,
  payrollId,
  setPendingDryRun,
  setPendingUpload
}) => {
  const history = useHistory();
  return (
    <>
      <PayrollDescription
        payroll={payroll}
        setPendingDryRun={setPendingDryRun}
        setPendingUpload={setPendingUpload}
      />

      <Card style={{ margin: '15px' }}>
        <CardHeader title="Uploads" />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(payroll.uploads)
              .sort((a, b) => a[1].startDate > b[1].startDate)
              .map((entry) => (
                <ExecutionRow
                  message={entry[1].message}
                  onClick={() =>
                    history.push(
                      `/echecks/payrolls/${payrollId}/execution/${entry[0]}`
                    )
                  }
                  startDate={entry[1].startDate}
                  status={entry[1].status}
                />
              ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export function EchecksPayrollDetail({ match }) {
  const [payroll, setPayroll] = useState();
  const [loading, setLoading] = useState(false);
  const refresh = async () => {
    setLoading(true);
    return getEchecksPayroll(match.params.payrollId)
      .then(setPayroll)
      .then(() => setLoading(false))
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  const setPendingAction = async (action) => {
    setLoading(true);
    await setEchecksPayrollPendingAction(match.params.payrollId, action);
    return await refresh();
  };
  useEffect(() => {
    refresh();
  }, [match.params.payrollId]);
  return (
    <>
      {loading && <Loader />}
      {!loading && !payroll && <>No payroll found</>}
      {!loading && payroll && (
        <PayrollDetails
          payroll={payroll}
          payrollId={match.params.payrollId}
          setPendingDryRun={() => setPendingAction('dryRunUpload')}
          setPendingUpload={() => setPendingAction('upload')}
        />
      )}
    </>
  );
}
