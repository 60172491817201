import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Divider, Drawer } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import SyncIcon from '@mui/icons-material/Sync';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LocalAtm from '@mui/icons-material/LocalAtm';
import WorkOff from '@mui/icons-material/WorkOff';
import HomeWork from '@mui/icons-material/HomeWork';

import { Profile, SidebarNav } from './components';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2),
  marginTop: theme.spacing(10)
}));

const WurkDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}));

const WurkSidebarNav = styled(SidebarNav)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  a: {
    width: '100%',
    justifyContent: 'flex-start',
    svg: {
      marginRight: '5px'
    }
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Jobs',
      href: '/jobs',
      icon: <WorkOutlineIcon />
    },
    {
      title: 'Admin Jobs',
      href: '/admin_jobs',
      icon: <HomeWork />
    },
    {
      title: 'Retired Jobs',
      href: '/retired_jobs',
      icon: <WorkOff />
    },
    {
      title: 'Job Executions',
      href: '/job_executions',
      icon: <WorkOutlineIcon />
    },
    {
      title: 'Integrations',
      href: '/integrations',
      icon: <SyncIcon />
    },
    {
      title: 'ABG Integrations',
      href: '/abg',
      icon: <SyncIcon />
    },
    {
      title: 'Failed Emails',
      href: '/failedemails',
      icon: <EmailOutlined />
    },
    {
      title: 'eChecks configuration',
      href: '/echecks/config',
      icon: <LocalAtm />
    },
    {
      title: 'eChecks payrolls',
      href: '/echecks/payrolls',
      icon: <LocalAtm />
    }
  ];

  return (
    <Drawer anchor="left" onClose={onClose} open={open} variant={variant}>
      <Container {...rest}>
        <Profile />
        <WurkDivider />
        <WurkSidebarNav pages={pages} />
      </Container>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
