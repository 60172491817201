import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  styled
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const Root = styled(Card)({
  height: '100%'
});

const Header = styled(CardHeader)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1.5)
}));

const Content = styled(CardContent)({
  height: '45vh',
  overflowY: 'auto'
});

const GridEntry = ({ children, title, refresh }) => {
  return (
    <Root>
      <Header
        action={
          <IconButton onClick={refresh} size="small">
            <RefreshIcon />
          </IconButton>
        }
        title={title}
      />
      <Divider />
      <Content>{children}</Content>
    </Root>
  );
};

export default GridEntry;
