import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  styled
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { JobResult, jobResultStyle } from 'components/JobExecution/JobResult';
import { ExecutionRuntime } from 'components/JobExecution/ExecutionRuntime';
import { StartedBy } from 'components/JobExecution/StartedBy';

const SortingTableHeaderCell = ({
  sortCriteria,
  setSortCriteria,
  fieldName,
  fieldDescription
}) => {
  const toggleSort = () => {
    return setSortCriteria({
      field: fieldName,
      direction:
        sortCriteria.field === fieldName && sortCriteria.direction === 'desc'
          ? 'asc'
          : 'desc'
    });
  };
  const sortDirectionIcon =
    sortCriteria.field !== fieldName ? (
      ''
    ) : (
      <span style={{ position: 'relative', top: '8px' }}>
        {sortCriteria.direction === 'asc' ? (
          <KeyboardArrowDownIcon style={{ transform: 'rotate(180deg)' }} />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </span>
    );
  return (
    <TableCell>
      <a
        onClick={toggleSort}
        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
      >
        {sortDirectionIcon} {fieldDescription}
      </a>
    </TableCell>
  );
};

const Content = styled(CardContent)({
  padding: 0
});

const JobTable = styled(Table)({
  minWidth: '1050px'
});

const SearchBox = styled(TextField)({
  width: '100%'
});

const clamp = (min, val, max) => Math.min(Math.max(min, val), max);

const JobsTable = (props) => {
  const {
    className,
    jobs,
    setJobs,
    jobNameFilter,
    setJobNameFilter,
    jobTypeFilter,
    setJobTypeFilter,
    sortCriteria,
    setSortCriteria,
    pageSize,
    jobIdFilter,
    setJobIdFilter,
    title,
    ...rest
  } = props;

  const [page, setPage] = useState(0);
  const clampedPage = clamp(0, page, Math.floor(jobs.length / pageSize));

  return (
    <Card {...rest}>
      <Content>
        <PerfectScrollbar>
          <JobTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                  <br />
                  <SearchBox
                    onChange={(e) => setJobNameFilter(e.target.value)}
                    type="text"
                    value={jobNameFilter}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  Type
                  <br />
                  <SearchBox
                    onChange={(e) => setJobTypeFilter(e.target.value)}
                    type="text"
                    value={jobTypeFilter}
                    variant="standard"
                  />
                </TableCell>
                <SortingTableHeaderCell
                  fieldDescription="Started at"
                  fieldName="startTime"
                  setSortCriteria={setSortCriteria}
                  sortCriteria={sortCriteria}
                />
                <TableCell>
                  JobId
                  <br />
                  <SearchBox
                    onChange={(e) => setJobIdFilter(e.target.value)}
                    type="text"
                    value={jobIdFilter}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>Execution UUID</TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Run Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs
                .slice(clampedPage * pageSize, (clampedPage + 1) * pageSize)
                .map((job) => (
                  <TableRow
                    hover
                    key={job._id}
                    onClick={() => {
                      window.location.href = `/job_executions/${job.uuid}`;
                    }}
                    style={jobResultStyle(job.result)}
                  >
                    <TableCell>{job.jobName}</TableCell>
                    <TableCell>{job.jobType}</TableCell>
                    <TableCell>
                      <abbr
                        title={moment(job.startTime).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      >
                        {moment
                          .duration(moment(job.startTime).diff(moment()))
                          .humanize(true)}
                      </abbr>
                      <br />
                      {moment(job.startTime).format('MM/DD/YYYY')}
                      <br />
                      <StartedBy execution={job} />
                    </TableCell>
                    <TableCell>{job.serviceId}</TableCell>
                    <TableCell>{job.uuid}</TableCell>
                    <TableCell>
                      <JobResult result={job.result} text />
                    </TableCell>
                    <TableCell>
                      <ExecutionRuntime execution={job} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </JobTable>
          <TablePagination
            component="div"
            count={jobs.length}
            onPageChange={(_evt, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => console.log(e)}
            page={clampedPage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
          />
        </PerfectScrollbar>
      </Content>
    </Card>
  );
};
JobsTable.defaultProps = {
  pageSize: 200,
  title: null
};

export default JobsTable;
