/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />;
});

/**
 * Component for showing the snackbar (small popup on the botton of the page)
 * for most usual actions (saving jobs, integrations and so on)
 * 
 * @component
 */
export function ActionCompletedSnackbar({open, onClose, onExited, errorMessage}) {
  return (
    <Snackbar
      autoHideDuration={6000}
      onClose={onClose}
      TransitionProps={{
        onExited
      }}
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        severity={errorMessage ? 'warning' : 'success'}
      >
        {errorMessage || 'Action executed successfully'}
      </Alert>
    </Snackbar>
  );
};

ActionCompletedSnackbar.propTypes = {
  /**
   * Shall it be shown?
   */
  open: PropTypes.bool,
  /**
   * What to do when the close button is clicked, or when the autoHideDuration expires?
   */
  onClose: PropTypes.func.isRequired,
  /**
   * What to do when the snackbar is closed?
   */
  onExited: PropTypes.func,
  /**
   * The error to show, or empty string for no error.
   */
  errorMessage: PropTypes.string
}
