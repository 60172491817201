import React, { useState, useEffect } from 'react';
import {
  getAllEchecksConfig,
  deleteEchecksConfig
} from '../../_services/ApiService';
import { EchecksConfigToolbar, EchecksConfigTable } from './components';
import { useSearchInput } from 'components/SearchInput';
import { styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const EchecksConfigsList = () => {
  const [configs, setConfigs] = useState(null);
  const [searchCriteria] = useSearchInput();

  const fetchData = async () => {
    const result = await getAllEchecksConfig();
    setConfigs(result);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const visibleConfig = (config) => {
    return (
      config.company?.id?.indexOf(searchCriteria) !== -1 ||
      config.company?.shortName?.indexOf(searchCriteria) !== -1
    );
  };

  const onDelete = async (id) => {
    await deleteEchecksConfig(id);
    await fetchData();
  };

  return (
    <Root>
      <EchecksConfigToolbar search={searchCriteria} />
      <Content>
        <EchecksConfigTable
          configs={configs && configs.filter(visibleConfig)}
          onDelete={onDelete}
        />
      </Content>
    </Root>
  );
};

export default EchecksConfigsList;
