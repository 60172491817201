import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditDialog, RemoveDialog } from './Management';

/**
 * Dropdown Menu for comment management options: `Edit`, `Remove`
 * @param {Object} props
 * @param {string} props._id - Comment id.
 * @param {string} props.text - Comment's original text
 */
export function CommentOption({ _id, text }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const hdlOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const hdlCloseMenu = () => {
    setComment(text);
    setAnchorEl(null);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const [comment, setComment] = useState(text);

  const handleChange = (event) => setComment(event.target.value);
  const hdlOpenEdit = () => {
    hdlCloseMenu();
    setOpenEdit(true);
  };
  const hdlCloseEdit = () => {
    setComment(text);
    setOpenEdit(false);
  };

  const [openRemove, setOpenRemove] = useState(false);
  const hdlOpenRemove = () => {
    hdlCloseMenu();
    setOpenRemove(true);
  };
  const hdlCloseRemmove = () => {
    setOpenRemove(false);
  };

  return (
    <>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="options"
        onClick={hdlOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={hdlCloseMenu}
        open={openMenu}
      >
        <MenuItem key="edit" onClick={hdlOpenEdit}>
          <ListItemIcon key="edit">
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography gutterBottom variant="h6">
            Edit
          </Typography>
        </MenuItem>
        <MenuItem key="remove" onClick={hdlOpenRemove}>
          <ListItemIcon key="remove">
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography gutterBottom variant="h6">
            Remove
          </Typography>
        </MenuItem>
      </Menu>

      <EditDialog
        close={hdlCloseEdit}
        comment={comment}
        id={_id}
        onChange={handleChange}
        open={openEdit}
        original={text}
      />
      <RemoveDialog close={hdlCloseRemmove} id={_id} open={openRemove} />
    </>
  );
}
