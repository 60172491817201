/* eslint-disable react/no-multi-comp */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 *
 * @typedef {Object} DialogMessageProps
 * @property {()=>{}} handleClose
 * @property {String} message
 */

/**
 *
 * @param {DialogMessageProps} props
 */
const DialogMessage = (props) => {
  const { message, handleClose } = props;

  return (
    <>
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        onClose={handleClose}
        open
        TransitionComponent={Transition}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message ? <p>{message}</p> : <CircularProgress />}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogMessage;
