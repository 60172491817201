/*2G eslint-disable react/no-multi-comp */
import React from 'react';
import moment from 'moment';

const RowName = ({ children }) => (
  <span style={{ display: 'inline-block', width: '100px' }}>{children}:</span>
);
const RowDetail = ({ children }) => (
  <span style={{ marginLeft: '20px' }}>{children}</span>
);

const Size = ({ bytes }) => {
  if (bytes < 950) {
    return <>{bytes} B</>;
  } else {
    const kb = bytes / 1024;
    if (kb < 950) {
      return <>{kb.toFixed(2)} KB</>;
    } else {
      const mb = kb / 1024;
      return <>{mb.toFixed(2)} MB</>;
    }
  }
};

const Time = ({ name, timestamp }) => {
  return (
    <>
      <RowName>{name}</RowName>
      <RowDetail>{moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}</RowDetail>
      <RowDetail>
        {moment.duration(moment(timestamp) - moment()).humanize(true)}
      </RowDetail>
      <RowDetail>{timestamp}</RowDetail>
    </>
  );
};

const Rights = ({ name, rights }) => {
  const r = rights.indexOf('r') !== -1 ? 'r' : '_';
  const w = rights.indexOf('w') !== -1 ? 'w' : '_';
  const x = rights.indexOf('x') !== -1 ? 'x' : '_';
  return (
    <RowDetail>
      <code>
        {name}={r}
        {w}
        {x}
      </code>
    </RowDetail>
  );
};

const Owner = ({ owner, group }) => {
  if (owner || group) {
    return (
      <RowDetail>
        <code>{[owner, group].filter((x) => x).join(':')}</code>
      </RowDetail>
    );
  } else {
    return [];
  }
};

const Permissions = ({ owner, group, rights }) => {
  return (
    <>
      <RowName>Permissions</RowName>
      <Owner owner={owner} group={group} />
      <Rights name="user" rights={rights.user} />
      <Rights name="group" rights={rights.group} />
      <Rights name="other" rights={rights.other} />
    </>
  );
};

const FileType = ({ type }) => {
  let strType = type;
  switch (type) {
    case 'd':
      strType = 'd (Directory)';
      break;
    case '-':
      strType = '- (File)';
      break;
  }

  return (
    <>
      <span style={{ display: 'inline-block', width: '100px' }}>
        File type:
      </span>
      <span style={{ marginLeft: '20px' }}>{strType}</span>
    </>
  );
};

export const FileName = ({ name }) => <h4>{name}</h4>;

export const Details = ({
  size,
  accessTime,
  type,
  modifyTime,
  owner,
  group,
  rights,
  path
}) => {
  return (
    <>
      <ul style={{ margin: '10px auto' }}>
        <li>
          <RowName>Path</RowName>
          <RowDetail>{path}</RowDetail>
        </li>
        {size && (
          <li>
            <RowName>Size</RowName>
            <RowDetail>
              <Size bytes={size} /> ({size} Bytes)
            </RowDetail>
          </li>
        )}
        {accessTime && (
          <li>
            <Time name="Access time" timestamp={accessTime} />
          </li>
        )}
        {modifyTime && (
          <li>
            <Time name="Modify time" timestamp={modifyTime} />
          </li>
        )}
        {type && (
          <li>
            <FileType type={type} />
          </li>
        )}
        {(owner || group || rights) && (
          <li>
            <Permissions owner={owner} group={group} rights={rights} />
          </li>
        )}
      </ul>
    </>
  );
};
