import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button
} from '@mui/material';
import {
  getJobExecutionByUuid,
  getJobExecutionAttachment
} from '../../_services/ApiService';
import { downloadFile } from '../../helpers';
import LogsViewer from '../../components/LogsViewer';
import { JobResult } from 'components/JobExecution/JobResult';
import { ExecutionRuntime } from 'components/JobExecution/ExecutionRuntime';
import { StartedBy } from 'components/JobExecution/StartedBy';

const ExecutionDetailTitle = ({ children }) => {
  // Make the text non-selectable so that you can triple-click some detail of the execution and copy it
  return <span style={{ userSelect: 'none' }}>{children}: </span>;
};

const JobExecutionTitle = ({ uuid }) => {
  const copy = () => {
    const url = new URL(window.location);
    url.pathname = `/job_executions/${uuid}`;
    navigator.clipboard.writeText(url);
  };
  return (
    <>
      <ExecutionDetailTitle>Execution</ExecutionDetailTitle>
      {uuid}{' '}
      <button onClick={copy} style={{ userSelect: 'none' }}>
        Copy link
      </button>
    </>
  );
};

const ListItemEntry = ({ title, value }) => {
  return (
    <ListItem>
      <ListItemText>
        <ExecutionDetailTitle>{title}</ExecutionDetailTitle>
        {value}
      </ListItemText>
    </ListItem>
  );
};

const JobExecutionDetail = ({ match }) => {
  const jobUuid = match.params.uuid;

  const [job, setJob] = useState({});

  useEffect(() => {
    getJobExecutionByUuid(jobUuid).then(setJob);
  }, [jobUuid]);

  const replacer = (_key, value) => {
    return !value
      ? '-'
      : typeof value === 'object'
        ? JSON.stringify(value).replaceAll(/[\\"]/g, '')
        : value;
  };

  const csvLogsHeaders = [
    'message',
    'type',
    'msg',
    'fullPath',
    'length',
    'directory',
    // 'contents',
    'actionDescription',
    'actionTaken',
    'clientMessage',
    // 'matchingDeductions',
    'updateToApply',
    // 'updatedDeduction',
    'UD.SSN',
    'UD.EIN Name',
    'UD.Deduction Name',
    'UD.StartDate',
    'UD.EndDate',
    'UD.EEFrequencyType',
    'UD.EE Amount Calc Method',
    'UD.EE Amount Percent',
    'UD.EE Amount Earnings List',
    'UD.Overwrite Existing Deduction',
    'UD.EE Amount Dollars',
    'UD.Vendor',
    'UD.EEFrequencyProperty',
    // 'companyName',
    // 'reportID',
    // 'jobType',
    // 'newLastRunDate',
    'createdAt',
    'config',
    'filter',
    'options'
  ];

  const exportLogs = (e) => {
    const { logs, jobName, startTime } = job;

    const data = [
      csvLogsHeaders.join(','),
      ...logs.map((row) => {
        return csvLogsHeaders
          .map((fieldName) => {
            const separedField = fieldName.split('.');
            const updatedDeduction = row['updatedDeduction'];
            let result = row[fieldName];
            if (separedField[0] === 'UD' && updatedDeduction) {
              result = updatedDeduction[separedField[1]];
            }
            return JSON.stringify(result, replacer);
          })
          .join(',');
      })
    ].join('\r\n');

    const fileName = `${jobName}_${startTime.slice(0, -5)}`;

    e.preventDefault();
    downloadFile({
      data,
      fileName,
      fileType: 'text/csv'
    });
  };

  const logsWithAttachments =
    job.logs &&
    job.logs.map((log) => {
      if (log.type === 'debugInfo') {
        return {
          ...log,
          data: {
            ...log.data,
            value: () =>
              getJobExecutionAttachment({
                uuid: jobUuid,
                attachmentDescriptor: log.data
              })
          }
        };
      } else {
        return log;
      }
    });

  return (
    <>
      <Card>
        <CardHeader title={<JobExecutionTitle uuid={jobUuid} />} />
        <CardContent>
          <List>
            <ListItemEntry title="Name" value={job.jobName} />
            <ListItemEntry title="Type" value={job.jobType} />
            <ListItemEntry
              title="Started at"
              value={
                job.startTime &&
                moment(job.startTime).format('MMMM Do YYYY, h:mm:ss a')
              }
            />
            <ListItemEntry
              title="Started By"
              value={<StartedBy execution={job} hidePrefix />}
            />
            <ListItemEntry
              title="Duration"
              value={<ExecutionRuntime execution={job} />}
            />
            <ListItemEntry
              title="Results"
              value={<JobResult result={job.result} />}
            />
            <ListItem>
              <Button
                color="primary"
                onClick={exportLogs}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                variant="outlined"
              >
                Download CSV
              </Button>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {job.logs && (
        <LogsViewer logs={logsWithAttachments} startTime={job.startTime} />
      )}
    </>
  );
};

export default JobExecutionDetail;
