import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Input, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParam } from 'helpers/useSearchParam';

const Root = styled(Paper)(({ theme }) => ({
  borderRadius: '4px',
  alignItems: 'center',
  padding: theme.spacing(1),
  display: 'flex',
  flexBasis: 420
}));

const Icon = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary
}));

const InputComponent = styled(Input)({
  flexGrow: 1,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.05px'
});

const SearchInput = (props) => {
  const { className, style, ...rest } = props;

  const [value, setValue] = useSearchParam('search');

  const onInputChange = async (event) => {
    const query = event.target.value;
    setValue(query);
  };

  return (
    <Root {...rest} className={className} style={style}>
      <Icon />
      <InputComponent
        {...rest}
        disableUnderline
        onChange={onInputChange}
        value={value}
      />
    </Root>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onSearchChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;

export function useSearchInput() {
  return useSearchParam('search');
}
