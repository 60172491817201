/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { getJob, updateJob } from '_services/ApiService';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Snackbar,
  styled
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import { CronDescription } from '../CreateJob/CronDescription';
import EditJobModal from '../CreateJob/Modal';
import { FullScreenLoader } from 'components/Loader';
import { useJobsContext } from 'components/JobList/JobsContext';
import { useSearchParam } from 'helpers/useSearchParam';
import { ModalContext } from '../CreateJob/ModalContext';
import { ActionCompletedSnackbar } from 'components/ActionCompletedSnackbar';

const Actions = styled(CardActions)({
  justifyContent: 'flex-end'
});

const Content = styled(CardContent)({
  padding: 0
});

const Inner = styled('div')({
  minWidth: 1050
});

const JobStatus = styled(TableCell)(({ theme, job }) => {
  if (job.status === 'disabled') {
    if (job.attempts === 6) {
      // It is disabled because it failed too much
      return {
        backgroundColor: theme.palette.error.ultralight
      };
    } else {
      // Has been manually disabled
      return {
        backgroundColor: theme.palette.disabled.ultralight,
        textDecoration: 'line-through'
      };
    }
  }
  if (job.status === 'error') {
    return {
      backgroundColor: theme.palette.error.ultralight
    };
  }
  if (job.status === 'enabled') {
    return {
      backgroundColor: theme.palette.success.ultralight
    };
  }
  if (job.attempts > 0) {
    return {
      backgroundColor: theme.palette.warning.ultralight
    };
  }
  return {};
});

const JobAttempts = styled(TableCell)(({ theme, job }) => {
  if (job.attempts > 3) {
    return {
      backgroundColor: theme.palette.error.ultralight
    };
  }
  if (job.attempts > 0) {
    return {
      backgroundColor: theme.palette.warning.ultralight
    };
  }
  return {};
});

const JobRow = styled(TableRow)({
  '& td': {
    textWrap: 'nowrap'
  }
});

function ModalWithContext({ state, setState, closeUpdateJob, onUpdateJob }) {
  const provider = {
    onClose: closeUpdateJob,
    isOpen: state.updateJobOpen,
    formState: state,
    setFormState: setState,
    onSubmitJob: onUpdateJob,
    isUpdate: true
  };

  return (
    <ModalContext.Provider value={provider}>
      <EditJobModal />
    </ModalContext.Provider>
  );
}

const JobsTable = (props) => {
  const {
    className,
    staticContext, // Destructuring staticContext to avoid passing this prop to the Card component
    ...rest
  } = props;

  const { jobs, refreshJobs, selectedJobs, setSelectedJobs, readonly } =
    useJobsContext();
  const [searchedJobName, setSearchedJobName] = useSearchParam('jobName');

  useEffect(() => {
    const searchQueryParamOptions = () => {
      if (!searchedJobName) {
        return;
      }

      const job = jobs?.find((job) => job.jobName === searchedJobName);
      if (job) {
        openUpdateJob(job._id);
      }
    };

    searchQueryParamOptions();
  }, [searchedJobName, jobs]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [state, setState] = useState({
    updateJobOpen: false,
    values: {
      _id: '',
      jobType: '',
      partner: '',
      lastRunDate: '',
      schedule: '',
      databaseName: '',
      status: '',
      config: '{ }'
    },
    errors: {},
    errorMessage: ''
  });
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (event) => {
    const { jobs } = props;

    let selectedJobs;

    if (event.target.checked) {
      selectedJobs = jobs.map((job) => job._id);
    } else {
      selectedJobs = [];
    }
    setSelectedJobs(selectedJobs);
  };

  const closeUpdateJob = () => {
    setSearchedJobName(null);
    setState({
      updateJobOpen: false,
      values: {
        _id: '',
        jobType: '',
        partner: '',
        lastRunDate: '',
        schedule: '',
        databaseName: '',
        status: '',
        config: '{ }'
      },
      errors: {},
      errorMessage: ''
    });
  };

  const openUpdateJob = async (jobId) => {
    setLoading(true);
    try {
      const job = await getJob(jobId);
      if (job.length !== 1) {
        throw new Error('Job not found');
      }
      const actualJob = job[0];
      const newJob = {
        ...actualJob,
        lastRunDate: moment(actualJob.lastRunDate).format('YYYY-MM-DDTHH:mm'),
        config: JSON.stringify(actualJob.config, null, 2)
      };

      setSearchedJobName(actualJob.jobName);

      setState({
        ...state,
        updateJobOpen: true,
        values: newJob
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpdateJob = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const job = state.values;
      await updateJob(job._id, {
        ...job,
        config: JSON.parse(job.config),
        lastRunDate: moment(job.lastRunDate, 'YYYY-MM-DDTHH:mm').toISOString()
      });
      refreshJobs();
      closeUpdateJob();
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        errorMessage: err.message
      });
    } finally {
      setLoading(false);
    }
    setShowSnackbar(true);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedJobs.indexOf(id);
    let newSelectedJobs = [];

    if (selectedIndex === -1) {
      newSelectedJobs = newSelectedJobs.concat(selectedJobs, id);
    } else if (selectedIndex === 0) {
      newSelectedJobs = newSelectedJobs.concat(selectedJobs.slice(1));
    } else if (selectedIndex === selectedJobs.length - 1) {
      newSelectedJobs = newSelectedJobs.concat(selectedJobs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedJobs = newSelectedJobs.concat(
        selectedJobs.slice(0, selectedIndex),
        selectedJobs.slice(selectedIndex + 1)
      );
    }

    setSelectedJobs(newSelectedJobs);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const clampedPage = Math.min(
    Math.max(0, page),
    Math.floor(jobs.length / rowsPerPage)
  );

  function companyForJob(job) {
    const company = job.config?.companyName?.toUpperCase();
    return company || '---';
  }

  const sortedJobs = [...jobs].sort((j1, j2) => {
    if(j1.createdDate < j2.createdDate) {
      return 1
    } else if(j1.createdDate > j2.createdDate) {
      return -1
    } else {
      return 0
    }
  })

  return (
    <>
      {loading && <FullScreenLoader />}
      <Card {...rest}>
        <Content>
          <PerfectScrollbar>
            <Inner>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedJobs.length === jobs.length}
                        color="primary"
                        indeterminate={
                          selectedJobs.length > 0 &&
                          selectedJobs.length < jobs.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Partner</TableCell>
                    <TableCell>Last Run Date</TableCell>
                    <TableCell>Schedule</TableCell>
                    <TableCell>Customer Name</TableCell>
                    {!readonly && <TableCell>Status</TableCell>}
                    {!readonly && <TableCell>Attempts</TableCell>}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedJobs
                    .slice(
                      rowsPerPage * clampedPage,
                      clampedPage * rowsPerPage + rowsPerPage
                    )
                    .map((job) => (
                      <JobRow
                        hover
                        key={job._id}
                        selected={selectedJobs.indexOf(job._id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedJobs.indexOf(job._id) !== -1}
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, job._id)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>{job.jobName}</TableCell>
                        <TableCell>{job.jobType}</TableCell>
                        <TableCell>{job.partner || '❌'}</TableCell>
                        <TableCell>
                          {moment(job.lastRunDate).toISOString()}
                        </TableCell>
                        <TableCell style={{ textWrap: 'initial' }}>
                          {job.runOnSchedule === true ? (
                            <CronDescription value={job.schedule} />
                          ) : (
                            <>Not scheduled</>
                          )}
                        </TableCell>
                        <TableCell>{companyForJob(job)}</TableCell>
                        {!readonly && (
                          <JobStatus job={job}>{job.status}</JobStatus>
                        )}
                        {!readonly && (
                          <JobAttempts job={job}>{job.attempts}</JobAttempts>
                        )}
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => openUpdateJob(job._id)}
                          >
                            {readonly ? <Visibility /> : <EditIcon />}
                          </IconButton>
                          <IconButton
                            component={RouterLink}
                            to={`/job_executions/all?jobId=${job._id}`}
                          >
                            <WorkOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </JobRow>
                    ))}
                </TableBody>
              </Table>
              <ModalWithContext
                closeUpdateJob={closeUpdateJob}
                onUpdateJob={onUpdateJob}
                setState={setState}
                state={state}
              />
            </Inner>
          </PerfectScrollbar>
        </Content>
        <Actions>
          <TablePagination
            component="div"
            count={jobs.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={clampedPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Actions>
        <ActionCompletedSnackbar
          open={showSnackbar}
          onClose={() => setShowSnackbar(false)}
          onExited={() => setState(state => ({...state, errorMessage: ''}))}
          errorMessage={state.errorMessage}
        />
      </Card>
    </>
  );
};

export default withRouter(JobsTable);
