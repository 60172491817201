export const allResultTypes = [
  'success',
  'warning',
  'error',
  'disabled',
  'other'
];

export function jobResultType(job) {
  const result = job.result;
  if (
    result.indexOf('error') !== -1 ||
    result.find((r) => r.type === 'error')
  ) {
    return 'error';
  } else if (
    result.indexOf('warning') !== -1 ||
    result.find((r) => r.type === 'warning')
  ) {
    return 'warning';
  } else if (
    result.indexOf('success') !== -1 ||
    result.find((r) => r.type === 'success')
  ) {
    return 'success';
  } else if (
    result.indexOf('disabled') !== -1 ||
    result.find((r) => r.type === 'disabled')
  ) {
    return 'disabled';
  } else {
    return 'other';
  }
}
