import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getEchecksPayrolls } from '../../_services/ApiService';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

function Loader() {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: '0',
        left: '0',
        zIndex: '10000'
      }}
    >
      <div
        style={{
          top: 'calc(50vh - 20px)',
          position: 'absolute',
          left: 'calc(50vw - 20px)'
        }}
      >
        <CircularProgress style={{ margin: 'auto' }} />
      </div>
    </div>
  );
}

export function ViewEchecksPayrolls() {
  const [payrolls, setPayrolls] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getEchecksPayrolls()
      .then(setPayrolls)
      .then(() => setLoading(false))
      .catch((e) => {
        console.log(e.error);
        alert(e.error);
      });
  }, []);
  const history = useHistory();
  return (
    <>
      {loading && <Loader />}
      <Card>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company (Kronos ID)</TableCell>
                <TableCell>EIN</TableCell>
                <TableCell>Payroll name (ID)</TableCell>
                <TableCell>Finalized on</TableCell>
                <TableCell>Pending actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payrolls && payrolls.length === 0 && (
                <TableRow>
                  <TableCell>No payrolls found</TableCell>
                </TableRow>
              )}
              {payrolls &&
                payrolls.length !== 0 &&
                payrolls.map((payroll) => (
                  <TableRow
                    hover
                    onClick={() =>
                      history.push(`/echecks/payrolls/${payroll.payrollId}`)
                    }
                  >
                    <TableCell>
                      {payroll.company.shortName} ({payroll.company.id})
                    </TableCell>
                    <TableCell>
                      {payroll.payroll.ein.number} - {payroll.payroll.ein.name}
                    </TableCell>
                    <TableCell>
                      {payroll.payroll.name} ({payroll.payrollId})
                    </TableCell>
                    <TableCell>{payroll.payroll.finalizedDate}</TableCell>
                    <TableCell>{payroll.status.pendingAction || '-'}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
