import React, { useState } from 'react';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  styled
} from '@mui/material';
import { ApiConfigDescription } from 'components';
import { CronDescription } from './CronDescription';
import { CommentSection } from 'components/JobComments';
import { submitComment } from 'components/JobComments/submit';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useJobsContext } from 'components/JobList';
import { useModalContext } from './ModalContext';

const Root = styled(Card)({
  width: '700px'
});

const TextAreaContainer = styled('div')({
  paddingTop: '25px',
  '& textarea': {
    fontFamily: 'monospace',
    fontSize: '0.8rem',
    lineHeight: 'initial'
  }
});

const CreateJob = (props) => {
  const { className, ...rest } = props;
  const { formState, setFormState, onSubmitJob, isUpdate } = useModalContext();

  const { jobTypes, readonly } = useJobsContext();

  const handleChange = (event) => {
    let errors = {};
    if (event.target.name === 'config') {
      try {
        JSON.parse(event.target.value);
      } catch (err) {
        errors.config = 'Invalid JSON';
      }
    } else if (event.target.name === 'jobName') {
      event.target.value = event.target.value.trim();
    }

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      errors: errors
    });
  };

  const toggleRunOnSchedule = (event) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        runOnSchedule: event.target.checked
      },
      errors: formState.errors
    });
  };

  const statuses = [
    {
      value: 'enabled',
      label: 'Enabled'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'error',
      label: 'Error'
    },
    {
      value: 'disabled',
      label: 'Disabled'
    },
    {
      value: 'retired',
      label: 'Retired'
    }
  ];

  const [comment, setComment] = useState('');

  const submitAndComment = async (event) => {
    event.preventDefault();
    await submitComment({
      content: comment,
      jobId: formState.values._id
    });
    onSubmitJob(event);
  };

  const onTypeChange = (event, newValue) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        jobType: newValue
      }
    });
  };

  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const { inputValue } = params;
    // New job type:
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
      filtered.push(inputValue);
    }
    return filtered;
  };

  const typeInput = (params) => (
    <TextField
      {...params}
      fullWidth
      label="Job type"
      margin="dense"
      name="jobType"
      variant="outlined"
    />
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Card
        style={{
          width: '600px',
          minHeight: '100px',
          maxHeight: '600px',
          overflowY: 'scroll'
        }}
      >
        <ApiConfigDescription jobType={formState?.values?.jobType} />
      </Card>
      <Root {...rest}>
        <form autoComplete="off" noValidate onSubmit={submitAndComment}>
          <CardHeader
            subheader={
              isUpdate
                ? `Update job ${formState.values.jobName}`
                : 'Create a new Job'
            }
            title="Job"
          />
          <Divider />
          <CardContent>
            {formState.errorMessage && (
              <h3 className="error"> {formState.errorMessage} </h3>
            )}
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  disableClearable
                  disabled={readonly}
                  filterOptions={filterOptions}
                  freeSolo
                  handleHomeEndKeys
                  id="auto-job-type"
                  onChange={onTypeChange}
                  options={jobTypes}
                  renderInput={typeInput}
                  required
                  selectOnFocus
                  value={formState.values.jobType}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={readonly}
                  fullWidth
                  label="Job name"
                  margin="dense"
                  name="jobName"
                  onChange={handleChange}
                  required
                  value={formState.values.jobName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={readonly}
                  fullWidth
                  label="Last Run Date"
                  margin="dense"
                  name="lastRunDate"
                  onChange={handleChange}
                  required
                  type="datetime-local"
                  value={
                    formState.values.lastRunDate
                      ? formState.values.lastRunDate
                      : moment(new Date()).format('YYYY-MM-DDTHH:mm')
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormGroup row style={{ flexWrap: 'nowrap' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.runOnSchedule}
                        disabled={readonly}
                        name="runOnSchedule"
                        onChange={toggleRunOnSchedule}
                      />
                    }
                    label={
                      <>
                        Schedule
                        <br />
                        active
                      </>
                    }
                    style={{ alignSelf: 'flex-start' }}
                  />
                  <TextField
                    disabled={readonly}
                    fullWidth
                    helperText={
                      <CronDescription value={formState.values.schedule} />
                    }
                    label="Schedule"
                    margin="dense"
                    name="schedule"
                    onChange={handleChange}
                    required={formState.values.runOnSchedule}
                    value={formState.values.schedule}
                    variant="outlined"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={readonly}
                  fullWidth
                  label="Database Name"
                  margin="dense"
                  name="databaseName"
                  onChange={handleChange}
                  required
                  value={formState.values.databaseName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={readonly}
                  fullWidth
                  label="Status"
                  margin="dense"
                  name="status"
                  onChange={handleChange}
                  required
                  value={formState.values.status}
                  variant="outlined"
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                >
                  {statuses.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={readonly}
                  fullWidth
                  label="Partner"
                  margin="dense"
                  name="partner"
                  onChange={handleChange}
                  value={formState.values.partner}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TextAreaContainer>
              <TextField
                disabled={readonly}
                error={formState.errors.config ? true : false}
                fullWidth
                helperText="Enter Valid JSON"
                label="Config"
                margin="dense"
                minRows={10}
                multiline
                name="config"
                onChange={handleChange}
                required
                value={
                  formState.values.config ? formState.values.config : '{ }'
                }
                variant="outlined"
              />
            </TextAreaContainer>

            {isUpdate && (
              <div>
                <br />
                <CommentSection
                  jobId={formState.values._id}
                  onChange={setComment}
                  readonly={readonly}
                  value={comment}
                />
              </div>
            )}
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              disabled={readonly}
              type="submit"
              variant="contained"
            >
              Save Job
            </Button>
          </CardActions>
        </form>
      </Root>
    </div>
  );
};

export default CreateJob;
