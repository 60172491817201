import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  styled
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import { getConfigSchema } from '../_services/ApiService';

const TogleableIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'toggled'
})(({ theme, toggled }) => ({
  transform: toggled ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const Expandable = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card style={{ marginLeft: '5px' }}>
      <CardHeader
        title={
          <>
            {title || ''}
            <TogleableIconButton
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
              toggled={expanded}
            >
              <ExpandMoreIcon />
            </TogleableIconButton>
          </>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

const ConfigDescriptionInner = ({ configSchema }) => {
  if (configSchema.type === 'object') {
    return (
      <>
        Fields:
        {configSchema.properties &&
          Object.entries(configSchema.properties).map(([k, v]) => (
            <>
              <dt>
                <strong>{k}</strong> {v.type && <>({v.type})</>}{' '}
                {configSchema.required?.indexOf(k) !== -1 ? '' : '(Required)'}
              </dt>
              <dd>
                <ConfigDescription configSchema={v} />
              </dd>
            </>
          ))}
      </>
    );
  } else if (configSchema.oneOf) {
    return (
      <>
        One of:{' '}
        <ul style={{ paddingLeft: '20px' }}>
          {configSchema.oneOf.map((c) => (
            <li>
              <ConfigDescription configSchema={c} />
            </li>
          ))}
        </ul>
      </>
    );
  } else if (configSchema.const) {
    return <>Constant value: {JSON.stringify(configSchema.const)}</>;
  } else if (configSchema.type === 'array') {
    return (
      <>
        Array of: <ConfigDescription configSchema={configSchema.items} />
      </>
    );
  } else {
    return <>Type: {configSchema.type}</>;
  }
};

const ConfigDescription = ({ configSchema }) => {
  return (
    <Expandable title={configSchema.description}>
      <ConfigDescriptionInner configSchema={configSchema} />
      {configSchema.examples && (
        <p>
          Examples:
          <ul style={{ paddingLeft: '20px' }}>
            {configSchema.examples.map((e) => (
              <li>{JSON.stringify(e)}</li>
            ))}
          </ul>
        </p>
      )}
      {configSchema.default && (
        <p>Default value: {JSON.stringify(configSchema.default)}</p>
      )}
    </Expandable>
  );
};

// Fetches details from the API
export const ApiConfigDescription = ({ jobType }) => {
  const [schema, setConfigSchema] = useState();
  useEffect(() => {
    if (jobType) {
      getConfigSchema(jobType)
        .then((r) => setConfigSchema(r))
        .catch(() => setConfigSchema(null));
    }
  }, [jobType]);
  return (
    <>{schema && <ConfigDescription configSchema={schema.configSchema} />}</>
  );
};

export default ConfigDescription;
