import React from 'react';
import { createAbgIntegration } from '_services/ApiService';
import { useHistory } from 'react-router-dom';
import AbgIntegrationForm from 'components/AbgIntegration/Form';

const AbgIntegrationCreate = (props) => {
  const history = useHistory();

  const onSubmit = async (formData) => {
    const created = await createAbgIntegration(v.formData);
    created._id && history.push(`/abg/${created._id}`);
  };
  return <AbgIntegrationForm onSubmit={onSubmit} />;
};

export default AbgIntegrationCreate;
