import React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useJobsContext } from 'components/JobList';
import { useSearchParam } from 'helpers/useSearchParam';

export default function SelectStatus() {
  const { onStatusChange } = useJobsContext();
  const onChange = (event) => onStatusChange(event.target.value);
  const [status] = useSearchParam('status');
  const statuses = ['Enabled', 'Pending', 'Error', 'Disabled'];

  return (
    <Box sx={{ minWidth: 80 }}>
      <FormControl fullWidth>
        <InputLabel id="select-status-label">Status</InputLabel>
        <Select
          color="secondary"
          id="status-select"
          label="Status"
          labelId="select-status-label"
          onChange={onChange}
          value={status}
        >
          <MenuItem value="All">All statuses</MenuItem>
          {statuses.map((status) => (
            <MenuItem key={status} value={status.toLocaleLowerCase()}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
