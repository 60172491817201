import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Link, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4)
}));

const Footer = (props) => {
  return (
    <Root {...props}>
      <Typography variant="body1">
        &copy;{' '}
        <Link component="a" href="https://enjoywurk.com/" target="_blank">
          Würk
        </Link>
        . 2020 - 2023
      </Typography>
    </Root>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
