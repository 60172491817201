import React from 'react';
import CreateJob from './index';
import { Modal, styled } from '@mui/material';
import { useModalContext } from './ModalContext';

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  'min-width': '600px',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.palette.shadows.primary,
  padding: theme.spacing(4, 4, 4),
  maxHeight: '95%',
  overflowY: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

const EditJobModal = () => {
  const { onClose, isOpen } = useModalContext();

  return (
    <Modal onClose={onClose} open={isOpen}>
      <Container>
        <CreateJob />
      </Container>
    </Modal>
  );
};

export default EditJobModal;
