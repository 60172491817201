export const FileViewer = ({ contents }) => {
  return (
    <textarea
      readOnly
      rows="12"
      style={{ width: '100%' }}
      wrap="off"
      defaultValue={contents}
    />
  );
};
