import React from 'react';
import moment from 'moment';

function executionDuration(execution) {
  if (!execution || !execution.startTime) {
    return null;
  }
  if (!execution.endTime) {
    return null;
  }
  const startTime = moment(execution.startTime);
  const endTime = moment(execution.endTime);
  const duration = moment.duration(endTime - startTime);
  return duration;
}

export const runtimeAsSeconds = (execution) => {
  const duration = executionDuration(execution);
  return duration && duration.asSeconds();
};

export const runtimeAsMinutesSeconds = (execution) => {
  const duration = executionDuration(execution);
  return (
    duration && `${Math.floor(duration.asMinutes())}min${duration.seconds()}s`
  );
};

export const ExecutionRuntime = ({ execution }) => {
  const duration = executionDuration(execution);
  if (!duration) {
    return null;
  }

  return (
    <abbr title={`${runtimeAsSeconds(execution)} seconds`}>
      {runtimeAsMinutesSeconds(execution)}
    </abbr>
  );
};
