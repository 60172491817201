import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import { FeedbackDialog } from './FeedbackDialog';
import { CommentsContext } from '../CommentContext';

/**
 * Dialog used to `Edit`a comment
 * @param {Object} props
 * @param {boolean} props.open - Boolean that represents the state
 * variable of the dialog
 * @param {Function} props.close - Function used for closing the dialog
 * @param {Function} props.original - Original comment to be compared
 * @param {string} props.comment - The comment value to be edited by
 * the user
 * @param {Function} props.onChange - Function that updates the comment's
 * value
 * @param {string|number} props.id - ID of the comment
 */
export function EditDialog({ open, close, original, comment, onChange, id }) {
  const { patchComment } = useContext(CommentsContext);
  const [loading, setLoading] = useState(false);
  const [feedValue, setFeedValue] = useState('Updated');

  const hdlCloseFeed = () => {
    setLoading(false);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      await patchComment(comment, original, id);
      setLoading(false);
    } catch (err) {
      setFeedValue(err.message);
    } finally {
      close();
    }
  };
  return (
    <>
      <FeedbackDialog
        close={hdlCloseFeed}
        content={feedValue}
        open={loading}
        title="Result from Edition"
      />
      <Dialog fullWidth onClose={close} open={open}>
        <DialogTitle>Edit Comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            id="name"
            label="Editing"
            margin="normal"
            minRows={1}
            multiline
            onChange={onChange}
            value={comment}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
