import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4)
}));

const Content = styled('div')({
  paddingTop: 150,
  textAlign: 'center'
});

const NotAllowed = () => {
  return (
    <Root>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <Content>
            <Typography variant="h1">
              403: Yout don't have permissions to access to this resource
            </Typography>

            <svg
              className="h-1 w-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
              />
            </svg>
          </Content>
        </Grid>
      </Grid>
    </Root>
  );
};

export default NotAllowed;
