import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography } from '@mui/material';
import {
  getJobExecutions,
  getThisWeekExecutions
} from '../../_services/ApiService';
import { DownloadButton, JobsTable } from './components';
import { useSearchParam } from 'helpers/useSearchParam';
import { Link, styled } from '@mui/material';
import { FullScreenLoader } from 'components/Loader/index';
import { InlineError } from 'components/Error';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const Page = ({ children }) => {
  return (
    <Root>
      <Content>{children}</Content>
    </Root>
  );
};

const PageTitle = ({ children }) => (
  <Typography variant="h1">{children}</Typography>
);

const JobsList = ({ jobs, title, pageSize }) => {
  const [jobNameFilter, setJobNameFilter] = useSearchParam('jobName');
  const [jobTypeFilter, setJobTypeFilter] = useSearchParam('jobType');
  const [jobIdFilter, setJobIdFilter] = useSearchParam('jobId');
  const [sortCriteria, setSortCriteria] = useState({
    field: 'startTime',
    direction: 'desc'
  });

  const sorter = (job1, job2) => {
    const field1 = job1[sortCriteria.field];
    const field2 = job2[sortCriteria.field];
    const multiplier = sortCriteria.direction === 'desc' ? -1 : 1;
    if (field1 === field2) {
      return 0;
    } else if (field1 > field2) {
      return 1 * multiplier;
    } else {
      return -1 * multiplier;
    }
  };

  return (
    <JobsTable
      jobIdFilter={jobIdFilter}
      jobNameFilter={jobNameFilter || ''}
      jobTypeFilter={jobTypeFilter}
      jobs={jobs
        .filter((j) => j.jobName.indexOf(jobNameFilter || '') !== -1)
        .filter((j) => j.jobType.indexOf(jobTypeFilter || '') !== -1)
        .filter((j) => j.serviceId.indexOf(jobIdFilter || '') !== -1)
        .sort(sorter)}
      pageSize={pageSize}
      setJobIdFilter={setJobIdFilter}
      setJobNameFilter={setJobNameFilter}
      setJobTypeFilter={setJobTypeFilter || ''}
      setSortCriteria={setSortCriteria}
      sortCriteria={sortCriteria}
      title={title}
    />
  );
};

const useGetJobs = (jobsFetcher) => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancelled = false;
    const source = axios.CancelToken.source();
    (async () => {
      setLoading(true);
      try {
        const result = await jobsFetcher(source.token);
        if (!cancelled) {
          setJobs(result);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('request cancelled');
        } else {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      cancelled = true;
      source.cancel();
    };
  }, []);
  return { jobs, error, loading };
};

const WeekJobExecutionsListInner = (props) => {
  const { jobs, error, loading } = useGetJobs(getThisWeekExecutions);
  if (loading) {
    return <FullScreenLoader />;
  }
  if (error) {
    return <InlineError error={error} />;
  }
  return <JobsList jobs={jobs} pageSize={200} />;
};

const AllTimeJobExecutionsListInner = (props) => {
  const { jobs, error, loading } = useGetJobs(getJobExecutions);
  if (loading) {
    return <FullScreenLoader />;
  }
  if (error) {
    return <InlineError error={error} />;
  }
  return <JobsList jobs={jobs} pageSize={500} />;
};

export const WeekJobExecutionsList = () => {
  const [jobNameFilter] = useSearchParam('jobName');
  const [jobTypeFilter] = useSearchParam('jobType');
  const [jobIdFilter] = useSearchParam('jobId');
  const jobName = jobNameFilter || '';
  const jobType = jobTypeFilter || '';
  const jobId = jobIdFilter || '';
  return (
    <Page>
      <PageTitle>
        Last 7 days of executions -{' '}
        <Link
          href={`/job_executions/all?jobName=${jobName}&jobType=${jobType}&jobId=${jobId}`}
          title="View all time"
        >
          View all time
        </Link>
        <DownloadButton isWeek />
      </PageTitle>
      <WeekJobExecutionsListInner />
    </Page>
  );
};

export const AllTimeJobExecutionsList = () => {
  const [jobNameFilter] = useSearchParam('jobName');
  const [jobTypeFilter] = useSearchParam('jobType');
  const [jobIdFilter] = useSearchParam('jobId');
  const jobName = jobNameFilter || '';
  const jobType = jobTypeFilter || '';
  const jobId = jobIdFilter || '';
  return (
    <Page>
      <PageTitle>
        All time executions -{' '}
        <Link
          href={`/job_executions?jobName=${jobName}&jobType=${jobType}&jobId=${jobId}`}
          title="View last week"
        >
          View last 7 days
        </Link>
        <DownloadButton />
      </PageTitle>
      <AllTimeJobExecutionsListInner />
    </Page>
  );
};
