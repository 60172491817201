import React from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue';

export function CronDescription({ value }) {
  return <abbr title={value}>{scheduleDescription(value)}</abbr>;
}
CronDescription.propTypes = {
  value: PropTypes.string.isRequired
};

export function scheduleDescription(schedule) {
  if (schedule.trim() === '') {
    return 'No schedule';
  }
  try {
    return cronstrue.toString(schedule, { verbose: true });
  } catch (e) {}
  // TODO: this regex comes from charlotte-jobs-schedule-runner
  const t = schedule.trim().toLowerCase();
  const regex =
    /^biweekly\((?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2}), (?<hour>\d{2}):(?<minute>\d{2})\)$/;
  const parsed = t.match(regex);
  if (parsed) {
    return `Biweekly, starting on ${parsed.groups.month}/${parsed.groups.day}/${parsed.groups.year}, at ${parsed.groups.hour}:${parsed.groups.minute}`;
  }
  return 'Invalid schedule';
}
