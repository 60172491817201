import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, styled } from '@mui/material';

const Root = styled(AppBar)({
  boxShadow: 'none'
});

const Topbar = (props) => {
  return (
    <Root {...props} color="primary" position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo.png" />
        </RouterLink>
      </Toolbar>
    </Root>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
