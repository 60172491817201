import React, { useState, useEffect } from 'react';
import Form from '@rjsf/mui';
import { Card, CardHeader, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getEchecksSchema,
  createEchecksConfig,
  getEchecksConfig,
  updateEchecksConfig,
  getEchecksAuthStatus,
  echecksGenerateAuthToken,
  echecksVerifyAuthToken
} from '../../_services/ApiService';
import { Button } from '@mui/material';
import validator from '@rjsf/validator-ajv8';

function EchecksAuthDetail({ companyId, environment, onRefresh }) {
  const [status_, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  async function refresh() {
    setLoading(true);
    try {
      const s = await getEchecksAuthStatus({ companyId, environment });
      setStatus(s);
    } finally {
      setLoading(false);
    }
  }
  async function generate() {
    setLoading(true);
    await echecksGenerateAuthToken({ companyId, environment });
    await refresh();
    onRefresh();
  }
  async function verify() {
    setLoading(true);
    await echecksVerifyAuthToken({ companyId, environment });
    await refresh();
    onRefresh();
  }

  useEffect(() => {
    refresh();
  }, []);

  const [formData, setFormData] = useState({});
  const s = status_ && status_.status;
  const description = {
    'token-not-found': <>No token found. Click 'Generate' to request a token</>,
    'token-is-valid': (
      <>
        Token has been generated. Please request the client to approve the
        connection, then click 'Verify' to verify the token.
      </>
    ),
    'token-is-verified': <>Token is verified. User token has been generated</>
  }[s];
  return (
    <>
      <h1>Authentication ({environment})</h1>
      <div>
        <ul>
          <li>{description}</li>
          <li style={{ display: 'flex', gap: '10px' }}>
            {s === 'token-not-found' && (
              <Button color="secondary" onClick={generate} variant="outlined">
                generate token
              </Button>
            )}
            {s === 'token-is-valid' && (
              <Button color="secondary" onClick={verify} variant="outlined">
                verify token
              </Button>
            )}
            {(s === 'token-is-verified' || s === 'token-is-valid') && (
              <Button color="secondary" onClick={generate} variant="outlined">
                Re-generate token
              </Button>
            )}
          </li>
        </ul>
      </div>
    </>
  );
}

const uiSchema = {
  kronos: {
    password: {
      type: { 'ui:widget': 'hidden' }
    }
  }
  //'kronos.password.type': {"ui:widget": "hidden"}
};

function EchecksConfigDetail({ title, initial, onSubmit }) {
  const [schema, setSchema] = useState();
  useEffect(() => {
    getEchecksSchema().then(setSchema);
  }, []);
  const [formData, setFormData] = useState(initial);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Card style={{ width: '50%', margin: '10px' }}>
        <CardHeader title={title} />
        <CardContent style={{ padding: '10px' }}>
          {schema && (
            <Form
              formData={formData}
              onChange={(v) => setFormData(v.formData)}
              onError={(c) => console.log('errors', c)}
              onSubmit={(a) => onSubmit(formData)}
              schema={schema}
              validator={validator}
            />
          )}
        </CardContent>
      </Card>
      <Card style={{ width: '40%', margin: '10px' }}>
        <CardContent>
          <pre>{JSON.stringify(formData, 0, 2)}</pre>
        </CardContent>
      </Card>
    </div>
  );
}

function Loader() {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: '0',
        left: '0',
        zIndex: '10000'
      }}
    >
      <div
        style={{
          top: 'calc(50vh - 20px)',
          position: 'absolute',
          left: 'calc(50vw - 20px)'
        }}
      >
        <CircularProgress style={{ margin: 'auto' }} />
      </div>
    </div>
  );
}

export function CreateEchecksConfig() {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await createEchecksConfig(data);
      setLoading(false);
      window.location.href = `/echecks/config/${data.company.id}`;
    } catch (e) {
      setLoading(false);
      alert(e.toString());
    }
  };
  return (
    <>
      {loading && <Loader />}
      <EchecksConfigDetail
        onSubmit={onSubmit}
        title={<>New eChecks configuration</>}
      />
    </>
  );
}

export function EditEchecksConfig({ match }) {
  console.log(match.params.companyId);
  const companyId = match.params.companyId;
  const [initialConfig, setInitialConfig] = useState();
  const [loading, setLoading] = useState(false);
  const loadConfig = async () => {
    setLoading(true);
    getEchecksConfig(match.params.companyId)
      .then(setInitialConfig)
      .then(() => setLoading(false))
      .catch((e) => {
        console.log(e.error);
        alert(e.error);
        window.location.href = '/echecks/config/';
      });
  };
  useEffect(() => {
    loadConfig();
  }, [match.params.companyId]);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await updateEchecksConfig(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert(e.error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {initialConfig && (
        <EchecksConfigDetail
          companyId={companyId}
          initial={initialConfig}
          onSubmit={onSubmit}
          title={<>eChecks configuration for {companyId}</>}
        />
      )}
      <EchecksAuthDetail
        companyId={companyId}
        environment="sandbox"
        onRefresh={loadConfig}
      />
      <EchecksAuthDetail
        companyId={companyId}
        environment="production"
        onRefresh={loadConfig}
      />
    </>
  );
}
