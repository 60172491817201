import React, { useState, useEffect } from 'react';
import { getFailedEmails } from '../../_services/ApiService';
import { FailedEmailsToolbar, FailedEmailsTable } from './components';
import { useSearchInput } from 'components/SearchInput';
import { styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const FailedEmailsList = () => {
  const [failedEmails, setFailedEmails] = useState([]);
  const [selectedFailedEmails, setSelectedFailedEmails] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchCriteria] = useSearchInput();

  useEffect(() => {
    (async () => {
      try {
        const result = await getFailedEmails({ page: page + 1, pageSize });
        setFailedEmails(result.data);
        setPageCount(result.total);
      } catch (err) {
        throw err;
      }
    })();
  }, [page, pageSize]);

  const visibleFailedEmails = failedEmails.filter(
    (failedEmail) =>
      // Matches on message
      failedEmail.message.uuid.indexOf(searchCriteria.toLowerCase()) !== -1 ||
      // Is selected
      selectedFailedEmails.indexOf(failedEmail._id) !== -1
  );

  return (
    <>
      <Root>
        <FailedEmailsToolbar
          failedEmails={visibleFailedEmails}
          selectedFailedEmails={selectedFailedEmails}
          setFailedEmails={setFailedEmails}
          setSelectedFailedEmails={setSelectedFailedEmails}
        />
        <Content>
          <FailedEmailsTable
            failedEmails={visibleFailedEmails || []}
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            selectedFailedEmails={selectedFailedEmails}
            setFailedEmails={setFailedEmails}
            setPage={setPage}
            setPageSize={setPageSize}
            setSelectedFailedEmails={setSelectedFailedEmails}
          />
        </Content>
      </Root>
    </>
  );
};

export default FailedEmailsList;
