import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  if (props.path === '/sign-in') {
    return (
      <Route
        {...rest}
        render={(matchProps) => {
          return (
            <Layout>
              <Component {...matchProps} {...rest} />
            </Layout>
          );
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }}
      />
    );
  }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
