import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'ramda';
import { Button, Box, styled } from '@mui/material';
import {
  createJob,
  runJob,
  getJob,
  updateJob
} from '_services/ApiService';
import {
  SearchInput,
  SelectCompany,
  SelectDay,
  SelectJobType,
  SelectStatus,
  DownloadJobs,
  SelectPartner
} from 'components';
import EditJobModal from '../CreateJob/Modal';
import moment from 'moment';
import { useJobsContext } from '../JobsContext';
import { ModalContext } from '../CreateJob/ModalContext';
import ConfirmButton from 'components/ConfirmButton';

const ButtonRow = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  columnGap: '10px',
  padding: theme.spacing(2)
}));

const FilterRow = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  columnGap: '10px',
  padding: theme.spacing(2)
}));

const Spacer = styled('span')({
  flexGrow: 1
});

const defaultJob = () => ({
  jobType: '',
  jobName: '',
  lastRunDate: moment().format('YYYY-MM-DDThh:mm'),
  schedule: '',
  runOnSchedule: true,
  databaseName: '',
  status: 'enabled',
  config: '{ }'
});

const JobsToolbar = (props) => {
  const { className, ...rest } = props;
  const { selectedJobs, refreshJobs, setSelectedJobs, readonly } =
    useJobsContext();

  // TODO: extract deleteDialogOpen, createJobOpen and runJobDialog
  // TODO: The above can be achieved using another context and breaking
  // TODO: down the Toolbar in smaller components if needed
  const [state, setState] = useState({
    deleteDialogOpen: false,
    createJobOpen: false,
    runJobDialogOpen: false,
    values: {
      ...defaultJob()
    },
    errors: {},
    errorMessage: ''
  });

  const [activateDialogOpen, setActivateDialogOpen] = useState(false);
  function toggleActivateJobDialog() {
    setActivateDialogOpen(!activateDialogOpen);
  }

  const toggleCreateJob = () => {
    setState({
      ...state,
      createJobOpen: !state.createJobOpen
    });
  };

  const runSelected = async () => {
    try {
      await Promise.all(
        map((jobId) => {
          return runJob(jobId);
        }, selectedJobs)
      );
      setSelectedJobs([]);
      await refreshJobs();
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmitJob = async (event) => {
    // TODO: this code is similar to the update job code. Can it be combined?
    event.preventDefault();
    if (Object.keys(state.errors).length > 0) {
      return;
    }

    try {
      const job = state.values;
      await createJob({
        ...job,
        config: JSON.parse(job.config),
        lastRunDate: moment(job.lastRunDate, 'YYYY-MM-DDTHH:mm').toISOString()
      });
      await refreshJobs();
      setState({
        deleteDialogOpen: false,
        createJobOpen: false,
        runJobDialogOpen: false,
        values: {
          ...defaultJob()
        },
        errors: {},
        errorMessage: ''
      });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        errorMessage: err.message
      });
    }
  };

  function statusSelect(dontShow) {
    if (dontShow) return <Box sx={{ minWidth: 5 }} />;
    return <SelectStatus />;
  }

  async function activateSelected() {
    try {
      await Promise.all(
        selectedJobs.map(async (jobId) => {
          const job = await getJob(jobId);
          const update = { ...job[0], status: 'disabled' };
          await updateJob(jobId, update);
        })
      );
      setSelectedJobs([]);
      await refreshJobs();
      toggleActivateJobDialog();
    } catch (err) {
      console.error(err);
    }
  }

  // TODO: Breack this in a sub component and create another context for this toggle, selected jobs and others
  function canModify(modify) {
    if (modify)
      return (
        <>
          <ConfirmButton
            acceptButtonText="Run Now"
            dialogBody={
              <>
                Are you sure you want to start the selected jobs?
                <br />
                <br />
                Be careful running multiple jobs at the same time
              </>
            }
            dialogTitle="Run Jobs Now?"
            disabled={!selectedJobs.length || readonly}
            onConfirm={runSelected}
            openDialogButtonText="Run Now"
          />

          <Button
            color="primary"
            disabled={readonly}
            onClick={toggleCreateJob}
            variant="contained"
          >
            Add Job
          </Button>
        </>
      );
  }

  const provider = {
    onClose: toggleCreateJob,
    isOpen: state.createJobOpen,
    formState: state,
    setFormState: setState,
    onSubmitJob,
    isUpdate: false
  };

  return (
    <ModalContext.Provider value={provider}>
      <div {...rest}>
        <ButtonRow>
          <Spacer />
          {canModify(!readonly)}
          {readonly && (
            <ConfirmButton
              acceptButtonText="Activate"
              dialogBody={
                <>
                  Are you sure you want to <strong>Activate</strong> the
                  selected jobs?
                  <br />
                  (This will mark the jobs as <strong>disabled</strong>)
                </>
              }
              dialogTitle="Activate jobs?"
              disabled={selectedJobs.length === 0}
              onConfirm={activateSelected}
              openDialogButtonText="Activate"
            />
          )}
          <EditJobModal />
        </ButtonRow>
        <FilterRow>
          <Spacer />
          <SearchInput placeholder="Search Job" />
          <SelectDay />
          <SelectJobType />
          <SelectCompany />
          <SelectPartner />
          {statusSelect(readonly)}
          <DownloadJobs />
        </FilterRow>
      </div>
    </ModalContext.Provider>
  );
};

JobsToolbar.propTypes = {
  className: PropTypes.string
};

export default JobsToolbar;
