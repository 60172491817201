export const roles = {
  DashboardView: ['ADMIN', 'OPERATOR', 'SUPPORT'],
  JobListView: ['ADMIN', 'OPERATOR'],
  AdminJobsView: ['ADMIN', 'OPERATOR'],
  RetiredJobsView: ['ADMIN', 'OPERATOR'],
  WeekJobExecutionsList: ['ADMIN', 'OPERATOR', 'SUPPORT'],
  AllTimeJobExecutionsList: ['ADMIN', 'OPERATOR', 'SUPPORT'],
  JobExecutionDetailView: ['ADMIN', 'OPERATOR', 'SUPPORT'],
  IntegrationListView: ['ADMIN', 'OPERATOR'],
  AbgIntegrationList: ['ADMIN', 'OPERATOR'],
  AbgIntegrationCreate: ['ADMIN', 'OPERATOR'],
  AbgIntegrationDetail: ['ADMIN', 'OPERATOR'],
  FailedEmailListView: ['ADMIN', 'OPERATOR'],
  CreateEchecksConfig: ['ADMIN', 'OPERATOR'],
  EditEchecksConfig: ['ADMIN', 'OPERATOR'],
  EchecksConfigsView: ['ADMIN', 'OPERATOR'],
  EchecksExecutionDetail: ['ADMIN', 'OPERATOR'],
  EchecksPayrollDetail: ['ADMIN', 'OPERATOR'],
  ViewEchecksPayrolls: ['ADMIN', 'OPERATOR']
};
