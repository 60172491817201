import React, { useState, useEffect } from 'react';
import { getAbgIntegration, updateAbgIntegration } from '_services/ApiService';
import FullScreenLoader from 'components/Loader';
import AbgIntegrationForm from 'components/AbgIntegration/Form';

const AbgIntegrationDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);

  async function load() {
    setLoading(true);
    const d = await getAbgIntegration(props.match.params.integrationId);
    setFormData(d);
    setLoading(false);
  }
  useEffect(() => {
    load();
  }, []);

  if (formData === null) {
    return <FullScreenLoader />;
  }

  const onSubmit = async (formData) => {
    await updateAbgIntegration(props.match.params.integrationId, formData);
    await load();
  };
  return <AbgIntegrationForm initialFormData={formData} onSubmit={onSubmit} />;
};

export default AbgIntegrationDetail;
