import React from 'react';
import { useJobsContext } from './JobsContext';
import { useSearchParam } from 'helpers/useSearchParam';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function SelectPartner() {
  const { onPartnerChange, partners } = useJobsContext();
  const [partner] = useSearchParam('partner');
  const onChange = (event) => onPartnerChange(event.target.value);

  return (
    <Box sx={{ minWidth: 90 }}>
      <FormControl fullWidth>
        <InputLabel id="select-partner-label">Partner</InputLabel>
        <Select
          color="secondary"
          id="partner-select"
          label="Partner"
          labelId="select-partner-label"
          onChange={onChange}
          value={partner}
        >
          <MenuItem value="All">All Partners</MenuItem>
          {partners?.map((partner) => (
            <MenuItem key={partner || 'no-partner'} value={partner || 'no-partner'}>
              {partner || 'No partner in job'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectPartner;
