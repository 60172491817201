import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { getJobs } from '_services/ApiService';
import { FullScreenLoader } from 'components/Loader';
import { useSearchParam } from 'helpers/useSearchParam';
import { JobsToolbar, JobsTable, JobsContext } from 'components/JobList';
import { useSearchInput } from 'components/SearchInput';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  paddingBlock: theme.spacing(4)
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}));

const JobsList = (props) => {
  const { filter, readonly } = props;

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const [dayCriteria, setDayCriteria] = useSearchParam('day');
  const [typeCriteria, setTypeCriteria] = useSearchParam('type');
  const [companyCriteria, setcompanyCriteria] = useSearchParam('company');
  const [statusCriteria, setStatusCriteria] = useSearchParam('status');
  const [partnerCriteria, setPartnerCriteria] = useSearchParam('partner');

  const [searchCriteria] = useSearchInput();

  const refreshJobs = async () => {
    setLoading(true);
    setJobs([]);
    try {
      const result = await getJobs();
      setJobs(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshJobs();
  }, []);

  function getProperties(jobs = []) {
    const rawTypes = [];
    const rawCompanies = [];
    const rawPartners = [];
    // Note: now we do a single loop to get all the properties
    for (let job of jobs) {
      rawTypes.push(job.jobType);
      rawCompanies.push(job.config?.companyName?.toUpperCase());
      rawPartners.push(job.partner?.toUpperCase() || undefined);
    }
    const jobTypes = [...new Set(rawTypes.sort())];
    const companies = [...new Set(rawCompanies.sort())];
    const partners = [...new Set(rawPartners.sort())];
    return { jobTypes, companies, partners };
  }

  // TODO: Find where to place all the commmon constants or types/ This type `RETIRED` is
  // TODO: already in Charlotte-jobs:
  const RETIRED = 'retired';
  const ADMIN = 'admin';
  const defaultFilter = ({ status, jobName }) => {
    const isActive = status !== RETIRED;
    const notAdmin = !jobName.toLowerCase().includes(ADMIN);
    return isActive && notAdmin;
  };
  const condition = filter || defaultFilter;
  // Here we try to be generic with the type of filtering for the page
  const activeJobs = jobs.filter(condition);

  const checkDayFilter = (job) => {
    if (dayCriteria === 'All' || !dayCriteria) return true;
    // dayCriteria has a day selected, but this job is unscheduleable
    if (!job.runOnSchedule) return false;

    const cronDay = job.schedule.trim().slice(-1);
    const scheduledDay = cronDay === '7' ? '0' : cronDay;
    return scheduledDay === dayCriteria;
  };
  const checkCompanyFilter = (job) => {
    const jobCompany = job.config?.companyName?.toUpperCase();
    if (companyCriteria === 'no-company') return !jobCompany;
    const checkCompany = companyCriteria && companyCriteria !== 'All';
    return checkCompany ? jobCompany === companyCriteria : true;
  };

  function checkPartnerFilter(job) {
    const jobPartner = job?.partner?.toUpperCase();
    if (partnerCriteria === 'no-partner') return !jobPartner;
    const checkPartner = partnerCriteria && partnerCriteria !== 'All';
    return checkPartner ? jobPartner === partnerCriteria : true;
  }

  const filteredJobs = activeJobs.filter((job) => {
    const { jobType, status } = job;
    const matchesDay = checkDayFilter(job);
    const matchedCompany = checkCompanyFilter(job);
    const matchedPartner = checkPartnerFilter(job);

    const checkType = typeCriteria && typeCriteria !== 'All';
    const matchesType = checkType ? jobType === typeCriteria : true;

    const checkStatus = statusCriteria && statusCriteria !== 'All';
    const matchesStatus = checkStatus ? status === statusCriteria : true;

    return (
      matchesDay &&
      matchesType &&
      matchedCompany &&
      matchesStatus &&
      matchedPartner
    );
  });

  const textIncludes = (text, subtext) => {
    if(!text) {
      return false
    }
    return text.toLowerCase().includes(subtext.toLowerCase())
  }

  const visibleJobs = filteredJobs.filter((job) => {
    const { _id, config } = job;
    const companyName = config?.companyName;
    const reportId =
      config?.reportDetails?.reportID ||
      config?.reportDetails?.demographicReport ||
      config?.reportID;
    const criteria = searchCriteria || '';
    return (
      // Matches on job name
      textIncludes(job.jobName, criteria) ||
      // Matches on job type
      textIncludes(job.jobType, criteria) ||
      // Matches on reportId
      textIncludes(reportId, criteria) ||
      // Matches on companyName
      textIncludes(companyName, criteria) ||
      // Is selected
      selectedJobs.includes(_id)
    );
  });

  const { jobTypes, companies, partners } = getProperties(activeJobs);

  const contextProvider = {
    jobs: visibleJobs,
    jobTypes,
    companies,
    partners,
    selectedJobs,
    setSelectedJobs,
    onDaySelectChange: setDayCriteria,
    onTypeSelectChange: setTypeCriteria,
    onCompanyChange: setcompanyCriteria,
    onStatusChange: setStatusCriteria,
    onPartnerChange: setPartnerCriteria,
    refreshJobs,
    readonly
  };

  return (
    <JobsContext.Provider value={contextProvider}>
      {loading && <FullScreenLoader />}
      <Root>
        <JobsToolbar />
        <Content>
          <JobsTable />
        </Content>
      </Root>
    </JobsContext.Provider>
  );
};

export default JobsList;
