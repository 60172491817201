import jwtDecode from 'jwt-decode';
import { msalConfig } from '../authConfig';
import { roles } from '../rolesConfig';

const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  const expires = new Date(localStorage.getItem('expires'));
  if (token && expires.getTime() > new Date().getTime()) {
    return true;
  }
  return false;
};

const signOut = () => {
  localStorage.clear();
  const {
    auth: { tenantId },
    logoutRedirectUrl
  } = msalConfig;
  const logoutRedirect = `https://login.microsoftonline.com/${tenantId}/oauth2/logout?post_logout_redirect_uri=${logoutRedirectUrl}`;
  window.location.href = logoutRedirect;
};

function matchRoles(admitedRoles, userRoles) {
  return admitedRoles.some((role) => userRoles.includes(role));
}

function isAuthorized(component) {
  const token = localStorage.getItem('token');
  const decoded = jwtDecode(token);
  if (!decoded.roles) return false;

  // Get admited roles for component:
  const admitedRoles = roles[component];
  if (!admitedRoles) return false;

  // Get roles from token:
  const userRoles = decoded.roles;

  return matchRoles(admitedRoles, userRoles);
}

export { isLoggedIn, signOut, isAuthorized };
