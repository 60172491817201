import React from 'react';
import { Button, styled } from '@mui/material';
import { SearchInput } from 'components';

const Row = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1)
}));

const Spacer = styled('span')({
  width: '10px'
});

const SearchBox = styled(SearchInput)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const EchecksConfigToolbar = (props) => {
  return (
    <div {...props}>
      <Row>
        <Spacer />
        <Button color="primary" href="/echecks/config/new" variant="contained">
          New configuration
        </Button>
      </Row>
      <Row>
        <SearchBox placeholder="Search" />
      </Row>
    </div>
  );
};

EchecksConfigToolbar.propTypes = {};

export default EchecksConfigToolbar;
