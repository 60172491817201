import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  Select,
  MenuItem,
  styled
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DebugInfoLogLine from './components/DebugInfoLogLine';
import TogleableIconButton from './components/TogleableIconButton';

const LogLineDetail = ({ value }) => {
  return <pre>{JSON.stringify(value, 0, 2)}</pre>;
};

const StandardLogLine = ({ line, startTime }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { type, message, msg, createdAt, ...rest } = line;
  const color = {
    info: '#fff',
    warn: '#ffc',
    error: '#fcc'
  }[type];

  const hasDetail = Object.entries(rest).length !== 0;

  return (
    <Card style={{ margin: '15px', backgroundColor: color }}>
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <span>
              {hasDetail && (
                <TogleableIconButton
                  aria-expanded={expanded}
                  aria-label="show more"
                  onClick={handleExpandClick}
                  toggled={expanded}
                >
                  <ExpandMoreIcon />
                </TogleableIconButton>
              )}
              {type.toUpperCase()} {message} {msg}
            </span>
            <span>
              {createdAt && new Date(createdAt).toISOString()}
              {createdAt && startTime && (
                <span style={{ marginLeft: '10px' }}>
                  +{(new Date(createdAt) - new Date(startTime)) / 1000}s
                </span>
              )}
            </span>
          </div>
        }
      />

      {hasDetail && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {Object.entries(rest).map(([key, value]) => (
              <Card key={key}>
                <CardHeader title={key} />
                <CardContent>
                  <LogLineDetail value={value} />
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

const EmailLogLine = ({ line }) => {
  const color = {
    successMessage: '#cfc',
    warningMessage: '#ffc',
    errorMessage: '#fcc'
  }[line.type];
  const title = {
    successMessage: (
      <>
        <EmailIcon /> Success email
      </>
    ),
    warningMessage: (
      <>
        <EmailIcon /> Warning email
      </>
    ),
    errorMessage: (
      <>
        <EmailIcon /> Error email
      </>
    )
  }[line.type];
  return (
    <Card style={{ margin: '15px', backgroundColor: color }}>
      <CardHeader title={title} />
      <CardContent>
        <List>
          {line.messages.map((m, index) => (
            <ListItem key={index}>
              <ListItemText>{m}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

const LogLine = ({ line, startTime }) => {
  switch (line.type) {
    case 'errorMessage':
    case 'warningMessage':
    case 'successMessage':
      return <EmailLogLine line={line} />;
    case 'debugInfo':
      return <DebugInfoLogLine line={line} />;
    default:
      return <StandardLogLine line={line} startTime={startTime} />;
  }
};

const alwaysIncluded = [
  'errorMessage',
  'debugInfo',
  'warningMessage',
  'successMessage'
];
const LogsViewer = ({ logs, startTime }) => {
  const [logLevel, setLoglevel] = useState('info');
  const filterLoglevel = (msg) => {
    if (alwaysIncluded.indexOf(msg.type) !== -1) {
      return true;
    }
    if (logLevel === 'info') {
      return ['info', 'warning', 'error'].indexOf(msg.type) !== -1;
    } else if (logLevel === 'debug') {
      return ['debug', 'info', 'warning', 'error'].indexOf(msg.type) !== -1;
    } else {
      return true;
    }
  };
  return (
    <Card style={{ margin: '15px' }}>
      <CardHeader title="Logs" />
      <Card style={{ margin: '15px' }}>
        Loglevel:
        <Select onChange={(e) => setLoglevel(e.target.value)} value={logLevel}>
          <MenuItem value="info">Info</MenuItem>
          <MenuItem value="debug">Debug</MenuItem>
          <MenuItem value="trace">Trace</MenuItem>
        </Select>
      </Card>
      {logs &&
        logs
          .filter(filterLoglevel)
          .map((logLine, index) => (
            <LogLine key={index} line={logLine} startTime={startTime} />
          ))}
    </Card>
  );
};

export default LogsViewer;
