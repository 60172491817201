import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { getIntegrations } from '../../_services/ApiService';
import { IntegrationsToolbar, IntegrationsTable } from './components';
import { useSearchInput } from 'components/SearchInput';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}));

const IntegrationsList = () => {
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchCriteria] = useSearchInput();

  useEffect(() => {
    (async () => {
      try {
        const result = await getIntegrations({ page: page + 1, pageSize });
        setIntegrations(result.data);
        setPageCount(result.total);
      } catch (err) {
        throw err;
      }
    })();
  }, [page, pageSize]);

  const visibleIntegrations = integrations.filter(
    (integration) =>
      // Matches on company name
      (integration.companyName || '')
        .toLowerCase()
        .indexOf(searchCriteria.toLowerCase()) !== -1 ||
      // Matches on event type
      (integration.eventType || '')
        .toLowerCase()
        .indexOf(searchCriteria.toLowerCase()) !== -1 ||
      // Is selected
      selectedIntegrations.indexOf(integration._id) !== -1
  );

  return (
    <>
      <Root>
        <IntegrationsToolbar
          integrations={visibleIntegrations}
          selectedIntegrations={selectedIntegrations}
          setIntegrations={setIntegrations}
          setSelectedIntegrations={setSelectedIntegrations}
        />
        <Content>
          <IntegrationsTable
            integrations={visibleIntegrations || []}
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            selectedIntegrations={selectedIntegrations}
            setIntegrations={setIntegrations}
            setPage={setPage}
            setPageSize={setPageSize}
            setSelectedIntegrations={setSelectedIntegrations}
          />
        </Content>
      </Root>
    </>
  );
};

export default IntegrationsList;
