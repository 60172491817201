import {
  createComment,
  patchComment,
  deleteComment
} from '_services/ApiService';
import jwtDecode from 'jwt-decode';

export const submitComment = async ({ content, jobId }) => {
  const currentComment = content.trim();
  if (currentComment) {
    const { name: author } = jwtDecode(localStorage.getItem('token'));
    const newComment = {
      author,
      time: new Date(),
      text: currentComment,
      jobId
    };
    await createComment(newComment);
  }
};

export const updateComment = async (content, original, id) => {
  const currentComment = content.trim();
  const originalComment = original.trim();
  if (currentComment && currentComment !== originalComment) {
    const { name } = jwtDecode(localStorage.getItem('token'));
    const update = {
      updatedBy: name,
      text: currentComment
    };
    const comment = await patchComment(id, update);
    return comment;
  } else {
    return originalComment;
  }
};

export const removeComment = async (id) => {
  const { name: updatedBy } = jwtDecode(localStorage.getItem('token'));
  const update = { updatedBy };
  const deleted = await deleteComment(id, update);
  return deleted;
};
