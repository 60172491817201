import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { getJobExecutions, getThisWeekExecutions } from '_services/ApiService';
import { createCsv, downloadFile } from 'helpers';
import moment from 'moment';
import { runtimeAsMinutesSeconds } from 'components';

export function DownloadButton(props) {
  const { title, isWeek } = props;
  const [jobs, setJobs] = useState([]);

  async function fetchExecutions() {
    const execsFetcher = isWeek ? getThisWeekExecutions : getJobExecutions;
    const result = await execsFetcher();
    setJobs(result);
  }

  function formatValue(value) {
    const lastEl = value[value.length - 1];
    const description = lastEl?.description?.replaceAll(',', '');
    return description ?? '-';
  }

  function replacer(key, value) {
    const isArray = Array.isArray(value);
    const newValue = isArray ? formatValue(value) : value;
    return !value ? '-' : newValue;
  }

  function formatElement(value) {
    return JSON.stringify(value, replacer);
  }

  function calcDuration({ startTime, endTime }) {
    if (!endTime) return;
    return runtimeAsMinutesSeconds({
      startTime: new Date(startTime),
      endTime: new Date(endTime)
    });
  }

  function formatRow(value) {
    const duration = calcDuration(value);
    if (duration) value = { ...value, duration };
    if (value?.result) value.result = formatElement(value.result);
    return value;
  }

  function convertArrayToCSV(values) {
    const headers = Object.keys(values[0]);
    headers.push('duration');
    const array = values.map(formatRow);
    return createCsv(array, headers);
  }

  const exportLogs = (event) => {
    const date = moment().format('YYYY-MM-DD, hh:mm A');
    const fileName = `${date}_file`;
    const data = convertArrayToCSV(jobs);
    event.preventDefault();
    downloadFile({
      data,
      fileName,
      fileType: 'text/csv'
    });
  };

  useEffect(() => {
    fetchExecutions();
  }, [jobs]);

  return (
    <Box component="span" sx={{ p: 2 }}>
      <Button color="primary" onClick={exportLogs} variant="contained">
        {title || 'Download'}
      </Button>
    </Box>
  );
}
