import React from 'react';
import PropTypes from 'prop-types';
import { indexOf } from 'ramda';
import { styled } from '@mui/material';
import { retryFailedEmail } from '../../../../_services/ApiService';
import { SearchInput } from 'components';
import ConfirmButton from 'components/ConfirmButton';

const Row = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1)
}));

const Spacer = styled('span')({
  flexGrow: 1
});

const SearchBox = styled(SearchInput)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const FailedEmailsToolbar = (props) => {
  const {
    className,
    failedEmails,
    setFailedEmails,
    selectedFailedEmails,
    setSelectedFailedEmails,
    ...rest
  } = props;

  const retrySelected = async () => {
    try {
      await Promise.all(selectedFailedEmails.map(retryFailedEmail));
      setFailedEmails([
        ...failedEmails.filter(
          (failedEmail) => indexOf(failedEmail._id, selectedFailedEmails) === -1
        )
      ]);
      setSelectedFailedEmails([]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div {...rest} className={className}>
      <Row>
        <Spacer />
        <ConfirmButton
          accceptButtonText="Retry"
          dialogBody="Are you sure you want to retry the selected Emails?"
          dialogTitle="Retry Failed Emails?"
          onConfirm={retrySelected}
          openDialogButtonText="Retry Failed Email(s)"
        />
      </Row>
      <Row>
        <SearchBox placeholder="Search Email" />
      </Row>
    </div>
  );
};

FailedEmailsToolbar.propTypes = {
  failedEmails: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      emailData: PropTypes.object,
      message: PropTypes.object
    })
  ),
  selectedFailedEmails: PropTypes.arrayOf(PropTypes.string),
  setFailedEmails: PropTypes.func,
  setSelectedFailedEmails: PropTypes.func
};

export default FailedEmailsToolbar;
