import React, { useState, useEffect } from 'react';
import { parse } from 'csv-parse/browser/esm';
import TableView from './TableView';

// Show value as JSON, with indentation and stuff
export const JSONView = ({ value }) => {
  const stringified = JSON.stringify(value, 0, 2);
  return (
    <pre>
      {stringified.slice(0, 5000)}
      {stringified.length > 5000 && <>&hellip;</>}
    </pre>
  );
};

// Show value pretending is a CSV string, converting into a table
export const CSVView = ({ value }) => {
  const [table, setTable] = useState([]);
  const [error, setError] = useState(null);
  // Parse value as CSV
  useEffect(() => {
    const parser = parse({ delimiter: ',' }); // Initialize the csv-parse parser
    let intermediateData = [];

    // Set up event listeners for parsing
    parser.on('readable', () => {
      let record;
      while ((record = parser.read())) {
        intermediateData.push(record);
      }
    });

    parser.on('error', (err) => setError(err.toString()));

    parser.on('end', () => {
      setTable(intermediateData);
    });

    parser.write(value); // Start the parsing
    parser.end(); // End the parsing
  }, [value]);

  return (
    <>
      {error && <>Error: {error}</>}
      <TableView value={table} />
    </>
  );
};

// Show value pretending is a string encoded in JSON
export const JSONText = ({ value }) => {
  try {
    if (typeof value !== 'string') {
      throw new Error('Not a string inside JSON');
    }
    const t = JSON.parse(value);
    return <pre>{t}</pre>;
  } catch (e) {
    return <>Invalid JSON text: {e.toString()}</>;
  }
};

// Show the value unformatted, as a simple string. Or convert to one.
export const UnformattedView = ({ value }) => {
  if (typeof value === 'string') {
    return <>{value}</>;
  } else {
    return <>{JSON.stringify(value)}</>;
  }
};
