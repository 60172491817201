import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { login } from '../../_services/ApiService';
import { styled } from '@mui/material/styles';
import { Grid, Button, IconButton, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isLoggedIn } from '../../_services/utils';
import SignInButton from 'components/SignInButton';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  '& > div': {
    height: '100%'
  }
}));

const QuoteSection = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
  '& > div': {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '& > div': {
      textAlign: 'center',
      flexBasis: '600px',
      '& > *': {
        color: theme.palette.white,
        fontWeight: 300
      }
    }
  }
}));

const Content = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

const ContentHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const ContentBody = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}));

const Form = styled('form')(({ theme }) => ({
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 700,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  h2: {
    marginTop: theme.spacing(3)
  },
  '& > div:not(:last-of-type)': {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = (props) => {
  const { history } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      await login(formState.values.email, formState.values.password);
      history.push('/');
    } catch (err) {
      setFormState((formState) => ({
        ...formState,
        errors: {
          email: ['Invalid Username or Password'],
          password: ['Invalid Username or Password']
        }
      }));
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (isLoggedIn()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Root>
      <Grid container>
        <QuoteSection item lg={5}>
          <div>
            <div>
              <Typography variant="h1">Login to Charlotte Admin</Typography>
              <div />
            </div>
          </div>
        </QuoteSection>
        <Content item lg={7} xs={12}>
          <div>
            <ContentHeader>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </ContentHeader>

            <ContentBody>
              <Form onSubmit={handleSignIn}>
                <Typography variant="h2">Sign in</Typography>
                <TextField
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign in now
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <SignInButton>Sign with Microsoft</SignInButton>
                  </Grid>
                </Grid>
              </Form>
            </ContentBody>
          </div>
        </Content>
      </Grid>
    </Root>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
