import React from 'react';
import { IconButton, Tooltip, Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import DisabledIcon from '@mui/icons-material/NotInterested';
import HelpIcon from '@mui/icons-material/Help';

const Result = ({ title, label, style, icon }) => {
  return (
    <Tooltip title={<pre>{title}</pre>}>
      <IconButton aria-label={label} size="small" style={style}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const SuccessResult = ({ title }) => {
  return (
    <Result
      icon={<CheckIcon />}
      label="success"
      style={{ backgroundColor: 'lightgreen' }}
      title={title}
    />
  );
};

const WarningResult = ({ title }) => {
  return (
    <Result
      icon={<WarningIcon />}
      label="warning"
      style={{ backgroundColor: '#FFFFBB' }}
      title={title}
    />
  );
};

const ErrorResult = ({ title }) => {
  return (
    <Result
      icon={<ErrorIcon />}
      label="error"
      style={{ backgroundColor: '#FFBBBB' }}
      title={title}
    />
  );
};

const DisabledResult = ({ title }) => {
  return (
    <Result
      icon={<DisabledIcon />}
      label="disabled"
      style={{ backgroundColor: '#BBBBBB' }}
      title={title}
    />
  );
};

const UnknownResult = ({ title }) => {
  return (
    <Result
      icon={<HelpIcon />}
      label="unknown"
      style={{ backgroundColor: '#BBBBBB' }}
      title={
        <>
          Unknown type
          <br />
          {title}
        </>
      }
    />
  );
};

const JobResultEntry = ({ entry }) => {
  if (entry.type === 'error') {
    return <ErrorResult title={entry.description} />;
  } else if (entry.type === 'warning') {
    return <WarningResult title={entry.description} />;
  } else if (entry.type === 'success') {
    return <SuccessResult title={entry.description} />;
  } else if (entry.type === 'disabled') {
    return <DisabledResult title={entry.description} />;
  } else if (entry === 'error') {
    return <ErrorResult title="Error email sent" />;
  } else if (entry === 'success') {
    return <SuccessResult title="Success email sent" />;
  } else {
    return <UnknownResult title={entry.description} />;
  }
};

export const JobResult = ({ result, text }) => {
  if (result === undefined) {
    return null;
  } else if (result.length === 0) {
    return '';
  } else {
    return (
      <Box sx={{ maxWidth: 200 }}>
        {result.map((r, i) => (
          <JobResultEntry entry={r} key={i} />
        ))}
        {text && result.length === 1 && (
          <Typography>{result[0].description}</Typography>
        )}
      </Box>
    );
  }
};

const styles = {
  error: { backgroundColor: '#FFCCCC' },
  warning: { backgroundColor: '#FFFFCC' },
  success: { backgroundColor: '#CCFFCC' },
  disabled: { backgroundColor: '#DDDDDD', textDecoration: 'line-through' },
  default: {}
};

export function jobResultStyle(result) {
  if (
    result.indexOf('error') !== -1 ||
    result.find((r) => r.type === 'error')
  ) {
    return styles.error;
  } else if (
    result.indexOf('warning') !== -1 ||
    result.find((r) => r.type === 'warning')
  ) {
    return styles.warning;
  } else if (
    result.indexOf('success') !== -1 ||
    result.find((r) => r.type === 'success')
  ) {
    return styles.success;
  } else if (
    result.indexOf('disabled') !== -1 ||
    result.find((r) => r.type === 'disabled')
  ) {
    return styles.disabled;
  } else {
    return styles.default;
  }
}
