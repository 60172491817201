import { useEffect, useState } from 'react';
import {
  diagnosticsSftpListDirectory,
  diagnosticsSftpGetFile,
  diagnosticsSftpDelete,
  diagnosticsSftpRename,
  diagnosticsSftpUploadFile
} from '_services/ApiService';
import Tree from 'rc-tree';
import 'assets/scss/rc-tree.scss';
import { downloadBase64 } from 'helpers/downloadFile';
import { FileName, Details } from './details';
import { FileViewer } from './file-viewer';
import { CardContent, Button } from '@mui/material';

function concatPath(dir, file) {
  if (dir.endsWith('/')) {
    return dir + file;
  } else {
    return dir + '/' + file;
  }
}

function getParentDir(path) {
  const parts = path.split('/');
  const parentDir = parts.slice(0, parts.length - 1);
  return parentDir.join('/');
}

function extractFileName(path) {
  const parts = path.split('/');
  return parts[parts.length - 1];
}

const MoveFile = ({ options, onSubmit, onCancel }) => {
  const [destination, setDestination] = useState('');
  return (
    <>
      Destination directory:
      <input
        list="dirlist"
        value={destination}
        onChange={(e) => setDestination(e.target.value)}
      />
      {options && (
        <datalist id="dirlist">
          {options.map((d) => (
            <option key={d} value={d} />
          ))}
        </datalist>
      )}
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onSubmit(destination)}
      >
        Move file
      </Button>
      <Button color="secondary" variant="contained" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
};

const RenameFile = ({ initialName, onSubmit, onCancel }) => {
  const [name, setName] = useState(initialName);
  return (
    <>
      New name:
      <input value={name} onChange={(e) => setName(e.target.value)} />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onSubmit(name)}
      >
        Rename file
      </Button>
      <Button color="secondary" variant="contained" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
};

const FileActions = ({
  fileName,
  parentDir,
  content,
  onViewFile,
  onDownloadFile,
  onDeleteFile,
  onRenameFile,
  knownDirectories
}) => {
  const [currentAction, setCurrentAction] = useState(null);
  if (currentAction === 'move') {
    return (
      <MoveFile
        options={knownDirectories}
        onSubmit={(newDir) => onRenameFile(concatPath(newDir, fileName))}
        onCancel={() => setCurrentAction(null)}
      />
    );
  }
  if (currentAction === 'rename') {
    return (
      <RenameFile
        initialName={fileName}
        onSubmit={(newName) => onRenameFile(concatPath(parentDir, newName))}
        onCancel={() => setCurrentAction(null)}
      />
    );
  }
  return (
    <>
      <CardContent>
        {!content && (
          <Button color="secondary" variant="contained" onClick={onViewFile}>
            View
          </Button>
        )}
        &nbsp;
        <Button color="secondary" variant="contained" onClick={onDownloadFile}>
          Download
        </Button>
        &nbsp;
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setCurrentAction('move')}
        >
          Move file
        </Button>
        &nbsp;
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setCurrentAction('rename')}
        >
          Rename file
        </Button>
        &nbsp;
        <Button
          color="secondary"
          variant="contained"
          style={{ backgroundColor: 'red' }}
          onClick={onDeleteFile}
        >
          Delete
        </Button>
      </CardContent>
    </>
  );
};

function FileDetails({
  details,
  path,
  fetchContent,
  onDeleteFile,
  onRenameFile,
  availableDirectories
}) {
  const [fileContents, setFileContents] = useState(undefined);
  const fetchFileContents = async () => {
    const r = await fetchContent();
    if (r.type === 'success') {
      setFileContents(r.value);
      return r.value;
    } else {
      alert(r.message);
    }
  };

  const parentDir = getParentDir(path);
  const fileName = extractFileName(path);

  const download = async () => {
    let data = fileContents;
    if (data === undefined) {
      data = await fetchFileContents();
    }
    if (data) {
      downloadBase64({
        data: btoa(data),
        fileName,
        fileType: 'application/octet-stream'
      });
    }
  };

  const deleteFile = async () => {
    if (confirm(`Do you really want to delete ${path}?`)) {
      return onDeleteFile();
    }
  };

  return (
    <>
      <FileName name={details.name} />
      <FileActions
        parentDir={parentDir}
        fileName={fileName}
        onViewFile={fetchFileContents}
        onDownloadFile={download}
        onDeleteFile={deleteFile}
        onRenameFile={onRenameFile}
        knownDirectories={availableDirectories}
      />
      <Details path={path} {...details} />
      {fileContents !== undefined && <FileViewer contents={fileContents} />}
    </>
  );
}

function UploadFile({ onSubmit, onCancel }) {
  const [file, setFile] = useState(undefined);
  const [fileContents, setFileContents] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        setFileContents(text);
      };
      reader.readAsText(file);
    } else {
      setFileContents(undefined);
    }
  }, [file]);
  const onUpload = async () => {
    setUploading(true);
    try {
      await onSubmit({ filename: file.name, contents: fileContents });
    } finally {
      setUploading(false);
    }
  };
  return (
    <>
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
      <Button
        disabled={!(file && fileContents !== undefined && !uploading)}
        onClick={onUpload}
        color="secondary"
        variant="contained"
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
      <Button onClick={onCancel} color="primary" variant="contained">
        Cancel
      </Button>
    </>
  );
}

function DirActions({ path, onUploadFile }) {
  const [action, setAction] = useState(undefined);
  if (action === 'upload') {
    return (
      <>
        <UploadFile
          onSubmit={(r) =>
            onUploadFile({
              path: concatPath(path, r.filename),
              contents: r.contents
            })
          }
          onCancel={() => setAction(undefined)}
        />
      </>
    );
  }
  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setAction('upload')}
      >
        Upload file
      </Button>
    </>
  );
}

const DirDetails = ({ path, details, onUploadFile }) => {
  return (
    <>
      <FileName name={details.name} />
      <DirActions path={path} onUploadFile={onUploadFile} />
      <Details path={path} {...details} />
    </>
  );
};

const updateTree = (tree, dir, dirInfo) => {
  return tree.map((branch) => {
    if (branch.key === dir) {
      return {
        ...branch,
        children: dirInfo.map((entry) => {
          if (entry.type === 'd') {
            return {
              title: entry.name,
              key: concatPath(dir, entry.name),
              isLeaf: false,
              detail: entry
            };
          } else {
            return {
              title: entry.name,
              key: concatPath(dir, entry.name),
              isLeaf: true,
              detail: entry
            };
          }
        })
      };
    } else {
      if (branch.children) {
        return {
          ...branch,
          children: updateTree(branch.children, dir, dirInfo)
        };
      } else {
        return branch;
      }
    }
  });
};

const treeToDirs = (tree) => {
  const result = [];
  tree.forEach((branch) => {
    if (!branch.isLeaf) {
      result.push(branch.key);
    }
    if (branch.children) {
      result.push(...treeToDirs(branch.children));
    }
  });
  return result;
};

export function SFTPBrowser({ credentials, initialDirectory }) {
  const [tree, setTree] = useState([
    {
      title: initialDirectory,
      key: initialDirectory,
      detail: { type: 'd', name: initialDirectory }
    }
  ]);
  const [path, setPath] = useState(undefined);
  const [selectedDetails, setSelectedDetails] = useState(undefined);

  const loadDirectory = async (dir) => {
    const r = await diagnosticsSftpListDirectory({
      credentials,
      directory: dir
    });
    if (r.type === 'success') {
      const updatedTree = updateTree(tree, dir, r.value);
      setTree(updatedTree);
    } else {
      alert(r.message);
    }
  };

  useEffect(() => {
    loadDirectory(initialDirectory);
  }, []);

  const loadData = async (query) => {
    const dir = query.key;
    await loadDirectory(dir);
  };

  const onSelect = (path, evt) => {
    if (path[0]) {
      setPath(path[0]);
      setSelectedDetails(evt.node.detail);
    }
  };

  const fetchFileContents = async () => {
    const r = await diagnosticsSftpGetFile({ credentials, path: path });
    if (r.type === 'success') {
      return {
        ...r,
        value: atob(r.value)
      };
    } else {
      return r;
    }
  };

  const deleteFile = async () => {
    const r = await diagnosticsSftpDelete({ credentials, path });
    if (r.type === 'success') {
      setSelectedDetails(undefined);
      setPath(undefined);
      await loadDirectory(getParentDir(path));
    } else {
      alert(r.message);
    }
  };

  const rename = async (newPath) => {
    const r = await diagnosticsSftpRename({ credentials, path, newPath });
    if (r.type === 'success') {
      await loadDirectory(getParentDir(path));
      setSelectedDetails(undefined);
      setPath(undefined);
    } else {
      alert(r.message);
    }
  };

  const onUploadFile = async ({ path, contents }) => {
    const r = await diagnosticsSftpUploadFile({
      credentials,
      path,
      contents: btoa(contents)
    });
    if (r.type === 'success') {
      await loadDirectory(getParentDir(path));
    } else {
      alert(r.message);
    }
  };

  return (
    <>
      <div
        style={{
          overflowY: 'scroll',
          height: '300px',
          maxHeight: '300px',
          borderRadius: '3px',
          border: '1px solid #333'
        }}
      >
        <Tree
          onSelect={onSelect}
          checkable={false}
          loadData={loadData}
          treeData={tree}
          selectedKeys={[path]}
          defaultExpandedKeys={[initialDirectory]}
        />
      </div>
      <div
        style={{
          width: '100%',
          overflowX: 'scroll',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      >
        {selectedDetails &&
          selectedDetails.type === '-' && [
            <FileDetails
              key={path}
              details={selectedDetails}
              path={path}
              fetchContent={fetchFileContents}
              onDeleteFile={deleteFile}
              onRenameFile={rename}
              availableDirectories={treeToDirs(tree)}
            />
          ]}
        {selectedDetails &&
          selectedDetails.type === 'd' && [
            <DirDetails
              key={path}
              details={selectedDetails}
              path={path}
              onUploadFile={onUploadFile}
            />
          ]}
      </div>
    </>
  );
}
