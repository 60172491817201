import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  styled
} from '@mui/material';

const TextAreaContainer = styled('div')({
  paddingTop: '25px'
});

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const CreateIntegration = (props) => {
  const {
    className,
    formState,
    setFormState,
    onSubmitIntegration,
    isUpdate,
    ...rest
  } = props;

  const testEmail = (val) => !val || val.match(EMAIL_REGEX) === null;

  const setError = (event) => {
    const {
      target: { name, value }
    } = event;
    let errors = {
      ...(name === 'emailRecipient'
        ? { [name]: testEmail(value) }
        : { [name]: !value })
    };
    if (['config', 'source'].indexOf(name) !== -1) {
      try {
        JSON.parse(value);
        errors[name] = false;
      } catch {
        errors[name] = true;
      }
    }
    setFormState({
      ...formState,
      errors: {
        ...formState.errors,
        ...errors
      }
    });
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      }
    });
  };

  return (
    <Card {...rest}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={!formState.errors.lenght && onSubmitIntegration}
      >
        <CardHeader
          subheader={`${isUpdate ? 'Update' : 'Create a new'} integration`}
          title="Integration"
        />
        <Divider />
        <CardContent>
          {formState.errorMessage && (
            <h3 className="error"> {formState.errorMessage} </h3>
          )}
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={formState.errors.companyName}
                fullWidth
                helperText={
                  formState.errors.companyName && 'Company name is required'
                }
                label="Company name"
                margin="dense"
                name="companyName"
                onBlur={setError}
                onChange={handleChange}
                required
                value={formState.values.companyName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {isUpdate && (
                <TextField
                  disabled
                  fullWidth
                  label="Integration ID"
                  margin="dense"
                  name="integrationId"
                  onChange={handleChange}
                  value={formState.values.integrationId}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={isUpdate}
                error={formState.errors.eventType}
                fullWidth
                helperText={
                  formState.errors.eventType && 'Event type is required'
                }
                label="Event type"
                margin="dense"
                name="eventType"
                onBlur={setError}
                onChange={handleChange}
                required
                value={formState.values.eventType}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.runnable}
                    name="runnable"
                    onChange={handleChange}
                  />
                }
                label="Runnable"
                margin="dense"
                value={formState.runnable}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextAreaContainer>
                <TextField
                  error={formState.errors.config}
                  fullWidth
                  helperText={formState.errors.config && 'Enter Valid JSON'}
                  label="Config"
                  margin="dense"
                  multiline
                  name="config"
                  onBlur={setError}
                  onChange={handleChange}
                  required
                  rows={10}
                  value={
                    formState.values.config ? formState.values.config : '{ }'
                  }
                  variant="outlined"
                />
              </TextAreaContainer>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextAreaContainer>
                <TextField
                  error={formState.errors.source}
                  fullWidth
                  helperText={formState.errors.source && 'Enter Valid JSON'}
                  label="Source"
                  margin="dense"
                  multiline
                  name="source"
                  onBlur={setError}
                  onChange={handleChange}
                  required
                  rows={10}
                  value={
                    formState.values.source ? formState.values.source : '{ }'
                  }
                  variant="outlined"
                />
              </TextAreaContainer>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" type="submit" variant="contained">
            Save Integration
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

CreateIntegration.propTypes = {
  formState: PropTypes.shape({
    values: PropTypes.shape({
      _id: PropTypes.string,
      companyName: PropTypes.string,
      integrationId: PropTypes.string,
      eventType: PropTypes.string,
      runnable: PropTypes.bool,
      config: PropTypes.string,
      source: PropTypes.string,
      createdDate: PropTypes.string,
      updatedDate: PropTypes.string
    }),
    deleteDialogOpen: PropTypes.bool,
    createIntegrationOpen: PropTypes.bool,
    errors: PropTypes.object,
    errorMessage: PropTypes.string
  }),
  isUpdate: PropTypes.bool.isRequired,
  onSubmitIntegration: PropTypes.func,
  setFormState: PropTypes.func
};

export default CreateIntegration;
