import React, { useState, useEffect, useMemo } from 'react';
import { Form } from '@rjsf/mui';
import { getConfigSchema } from '_services/ApiService';
import {
  Comment,
  Schedule,
  OnePasswordItemChooser
} from './CreateJobAdvancedWidgets';
import { submitComment } from 'components/JobComments/submit';
import './CreateJobAdvanced.scss';
import validator from '@rjsf/validator-ajv8';
import { useModalContext } from './ModalContext';

const jobSchema = {
  title: 'Configuration for job',
  type: 'object',
  properties: {
    jobType: {
      title: 'Job type',
      type: 'string'
    },
    jobName: {
      title: 'Job name',
      type: 'string'
    },
    lastRunDate: {
      title: 'Last run',
      type: 'string'
    },
    schedule: {
      title: 'Schedule',
      type: 'string'
    },
    runOnSchedule: {
      title: 'Schedule active',
      type: 'boolean'
    },
    databaseName: {
      type: 'string'
    },
    status: {
      type: 'string',
      enum: ['enabled', 'pending', 'error', 'disabled', 'retired']
    },
    partner: {
      type: 'string',
      name: 'Partner'
    },
    config: {
      type: 'string'
    },
    comment: {
      type: 'string',
      default: ''
    }
  },
  required: [
    'jobType',
    'jobName',
    'lastRunDate',
    'runOnSchedule',
    'databaseName',
    'status',
    'config'
  ],
  allOf: [
    {
      if: {
        properties: { runOnSchedule: { const: true } }
      },
      then: {
        required: ['schedule']
      }
    }
  ]
};

function useGetJobConfigSchema(jobType) {
  const [configSchema, setConfigSchema] = useState();
  useEffect(() => {
    if (jobType) {
      getConfigSchema(jobType)
        .then((r) => setConfigSchema(r))
        .catch((e) => {
          setConfigSchema({});
          console.error(e);
        });
    } else {
      setConfigSchema({});
    }
  }, [jobType]);
  return configSchema;
}

const CreateJobAdvanced = () => {
  const { formState, setFormState, onSubmitJob } = useModalContext();

  console.log(formState.values.jobType);
  const configSchema = useGetJobConfigSchema(formState.values.jobType);

  const widgets = useMemo(
    () => ({
      Schedule,
      Comment: Comment(formState.values._id),
      OnePasswordItemChooser
    }),
    [formState.values._id]
  );

  if (!configSchema) {
    return <h2>Loading...</h2>;
  }
  if (!configSchema.configSchema) {
    return <h2>Advanced editor is not available for this job type</h2>;
  }
  const uiSchema = {
    // FIXME: for some reason the submit button options are not being picked
    'ui:options': {
      submitButtonOptions: {
        norender: true,
        submitText: 'Confirm Details',
        props: {
          disabled: false,
          className: 'btn btn-info'
        }
      }
    },
    'ui:submitButtonOptions': {
      submitText: 'Confirm Details',
      norender: false,
      props: {
        disabled: false,
        className: 'btn btn-info'
      }
    },
    schedule: {
      'ui:options': {
        widget: 'Schedule'
      }
    },
    comment: {
      'ui:options': {
        widget: 'Comment'
      }
    },
    config: configSchema.configUiSchema || {
      kronos: {
        type: {
          'ui:disabled': true
        },
        '1password_item_path': {
          'ui:widget': 'OnePasswordItemChooser'
        }
      },
      sftp: {
        type: {
          'ui:disabled': true
        },
        '1password_item_path': {
          'ui:widget': 'OnePasswordItemChooser'
        }
      }
    }
  };

  const fullSchema = {
    ...jobSchema,
    properties: {
      ...jobSchema.properties,
      config: configSchema.configSchema
    }
  };

  // The job config is stored as JSON, so we have to match them
  let parsedConfig;
  try {
    parsedConfig = {
      ...formState.values,
      config:
        typeof formState.values.config === 'string'
          ? JSON.parse(formState.values.config)
          : formState.values.config
    };
  } catch (e) {
    return <h2>Invalid JSON detected in the configuration</h2>;
  }

  const handleChange = (v) => {
    setFormState({
      ...formState,
      values: {
        ...v.formData,
        config: JSON.stringify(v.formData.config, 0, 2)
      }
    });
  };

  const submitAndComment = async (e) => {
    await submitComment({ content: e.formData.comment, jobId: e.formData._id });
    // XXX: Fake preventDefault thing because the upstream form expects it. We should refactor it away.
    onSubmitJob({ preventDefault: () => {} });
  };

  return (
    <Form
      formData={parsedConfig}
      onChange={handleChange}
      onSubmit={submitAndComment}
      schema={fullSchema}
      uiSchema={uiSchema}
      validator={validator}
      widgets={widgets}
    />
  );
};

export default CreateJobAdvanced;
