import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { FeedbackDialog } from './FeedbackDialog';
import { CommentsContext } from '../CommentContext';

/**
 * Dialog used to `Remove` a comment
 * @param {Object} props
 * @param {boolean} props.open - Boolean that represents the state
 * variable of the dialog
 * @param {Function} props.close - Function used for closing the dialog
 * @param {string|number} props.id - ID of the comment
 */
export function RemoveDialog({ open, close, id }) {
  const { deleteComment } = useContext(CommentsContext);
  const [loading, setLoading] = useState(false);
  const [feedValue, setFeedValue] = useState('Removed');

  const hdlCloseFeed = () => {
    setLoading(false);
  };

  const onAgree = async () => {
    try {
      setLoading(true);
      await deleteComment(id);
    } catch (err) {
      setFeedValue(err.message);
    } finally {
      close();
    }
  };
  return (
    <>
      <FeedbackDialog
        close={hdlCloseFeed}
        content={feedValue}
        open={loading}
        title="Deleted Result"
      />
      <Dialog fullWidth onClose={close} open={open}>
        <DialogTitle>Remove Comment?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action is irreversible, the comment will continue to appear in
            the logs of previous executions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Disagree</Button>
          <Button onClick={onAgree}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
