import React, { useState, useEffect } from 'react';
import { CronDescription } from '../CronDescription';
import { CommentSection } from 'components/JobComments';
import Autocomplete from '@mui/material/Autocomplete';
import {
  getAvailable1PasswordFields,
  getAvailable1PasswordItems
} from '_services/ApiService';
import { TextField } from '@mui/material';
import { useJobsContext } from 'components/JobList';

export const Comment = (jobId) => ({ value, onChange }) => {
  const { readonly } = useJobsContext();
  return (
    <CommentSection
      jobId={jobId}
      onChange={onChange}
      readonly={readonly}
      value={value}
    />
  );
};

export const Schedule = (props) => {
  const Text = props.registry.widgets.TextWidget;
  return (
    <Text {...props} helperText={<CronDescription value={props.value} />} />
  );
};

export const OnePasswordItemChooser = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchSecrets() {
      const opts = await getAvailable1PasswordItems();
      setOptions(opts.sort());
    }
    fetchSecrets();
  }, []);

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        <TextField {...params} label="Path to secret" variant="outlined" />
      )}
      value={props.value}
    />
  );
};

export const OnePasswordFieldChooser = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchSecrets() {
      const opts = await getAvailable1PasswordFields();
      setOptions(opts.sort());
    }
    fetchSecrets();
  }, []);

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        <TextField {...params} label="Path to secret" variant="outlined" />
      )}
      value={props.value}
    />
  );
};
