import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Avatar
} from '@mui/material';
import moment from 'moment';
import { CommentOption } from './CommentOption';
import jwtDecode from 'jwt-decode';

/**
 * Creates a single card from a comment object
 * @param {Object} props - props received
 * @param {Object} props.rfs - Function to refresh comments list
 * @param {Object} props.comment - Object that wraps the comment's properties
 * @param {string} props.comment._id - Comment id.
 * @param {string} props.comment.author - Author of the comment.
 * @param {string} props.comment.time - The time when the comment was created.
 * @param {string} props.comment.text - Text of the comment.
 * @param {string} props.comment.updatedBy - Last person who edited the comment.
 * @param {string} props.comment.updatedDate - Last date when the comment was edited.
 */
export function CommentCard({
  comment: { _id, author, time, text, updatedBy, updatedDate }
}) {
  const { name: userName } = jwtDecode(localStorage.getItem('token'));
  const avatar = `https://ui-avatars.com/api/?name=${author}&length=2&background=6a329f&color=fff&size=16`;
  const timeFormated = moment(updatedDate ?? time).format(
    'YYYY-MM-DD, hh:mm A'
  );
  const isSameUser = author === userName;
  const options = () => {
    if (isSameUser)
      return <CommentOption _id={_id} author={author} text={text} />;
  };
  const edited = updatedBy ? ' - edited' : '';

  return (
    <Card>
      <CardHeader
        action={options()}
        avatar={<Avatar alt={author} src={avatar} />}
        subheader={timeFormated}
        title={author + edited}
      />
      <CardContent>
        <TextField
          InputProps={{
            readOnly: true
          }}
          color="primary"
          fullWidth
          id={_id}
          minRows={1}
          multiline
          value={text}
        />
      </CardContent>
    </Card>
  );
}
