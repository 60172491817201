import axios from 'axios';

const request = async (method, path, query, body, cancelToken) => {
  try {
    const request = {
      url: path,
      method: method,
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      params: query,
      data: body
    };
    if (cancelToken) {
      request.cancelToken = cancelToken;
    }
    const response = await axios.request(request);
    return response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      localStorage.clear();
      window.location.replace('/sign-in');
    } else if (err.response?.status === 403) {
      window.location.replace('/not-allowed');
    } else {
      throw err.response?.data;
    }
  }
};

export const login = async (username, password) => {
  const response = await axios.request({
    url: 'auth/login',
    method: 'post',
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(username + ':' + password).toString(
        'base64'
      )}`
    }
  });
  const expires = new Date();
  expires.setSeconds(new Date().getSeconds() + response.data.expiresIn);
  localStorage.setItem('token', response.data.token);
  localStorage.setItem('expires', expires);
  return true;
};

export const getJobs = async (cancelToken) => {
  return await request('get', '/jobs', null, null, cancelToken);
};

export const getJob = async (_id) => {
  return await request('get', `/jobs/${_id}`, null, null);
};

export const getJobExecutions = async (cancelToken) => {
  return await request('get', '/job_executions', null, null, cancelToken);
};

export const getTodaysExecutions = async (cancelToken) => {
  return await request('get', '/job_executions/today', null, null, cancelToken);
};

export const getThisWeekExecutions = async (cancelToken) => {
  return await request(
    'get',
    '/job_executions/this-week',
    null,
    null,
    cancelToken
  );
};

export const getJobExecutionByUuid = async (uuid) => {
  return await request('get', `/job_executions/${uuid}`, null, null);
};

export const getJobExecutionAttachment = async ({
  uuid,
  attachmentDescriptor
}) => {
  return await request(
    'POST',
    `/job_executions/${uuid}/attachment/`,
    null,
    attachmentDescriptor
  );
};

export const createJob = async (job, cancelToken) => {
  return await request('post', '/jobs', null, job, cancelToken);
};

export const updateJob = async (id, job) => {
  return await request('patch', `/jobs/${id}`, null, job);
};

export const runJob = async (id) => {
  return await request('patch', `/jobs/${id}/run`);
};

export const getIntegration = async (id) => {
  return await request('get', `/integrations/${id}`);
};

export const getIntegrations = async (params) => {
  return await request('get', '/integrations', params, null);
};

export const createIntegration = async (integration) => {
  return await request('post', '/integrations', null, integration);
};

export const deleteIntegration = async (id) => {
  return await request('delete', `/integrations/${id}`);
};

export const updateIntegration = async (id, integration) => {
  return await request('put', `/integrations/${id}`, null, integration);
};

export const runIntegration = async (integrationId) => {
  return await request('post', `/integrations/${integrationId}/run`);
};

export const getEvents = async (id, params) => {
  return await request('get', `/integrations/${id}/events`, params, null);
};

export const runEvent = async (id, integrationId) => {
  return await request(
    'post',
    `/integrations/${integrationId}/events/${id}/replay`
  );
};

export const getFailedEmails = async (params) => {
  return await request('get', '/failedemails', params, null);
};

export const retryFailedEmail = async (id) => {
  return await request('patch', `/failedemails/${id}/retry`);
};

export const testJob = async (job) => {
  return request('post', '/jobs/test-kronos', null, job);
};

export const testSftp = async (sftp) => {
  return await request('post', '/jobs/test-sftp', null, sftp);
};

export const getConfigSchema = async (jobType) => {
  return await request('get', `/job_config_schemas/${jobType}`);
};

export const getEchecksSchema = async () => {
  return await request('get', '/echecks/configurations/schema');
};

export const getAllEchecksConfig = async () => {
  return await request('get', '/echecks/configurations/');
};

export const getEchecksConfig = async (companyId) => {
  return await request('get', `/echecks/configurations/${companyId}`);
};

export const createEchecksConfig = async (config) => {
  return await request('post', '/echecks/configurations', null, config);
};

export const updateEchecksConfig = async (config) => {
  return await request('put', '/echecks/configurations', null, config);
};

export const deleteEchecksConfig = async (companyId) => {
  return await request('delete', `/echecks/configurations/${companyId}`);
};

export const getEchecksPayrolls = async () => {
  return await request('get', '/echecks/payrolls/');
};

export const authenticateWithMicrosoft = async (code) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/microsoft`,
    {
      code
    }
  );
  const expires = new Date();
  expires.setSeconds(new Date().getSeconds() + response.data.expiresIn);
  localStorage.setItem('token', response.data.token);
  localStorage.setItem('expires', expires);
};

export const getEchecksPayroll = async (id) => {
  return await request('get', `/echecks/payrolls/${id}`);
};

export const getEchecksLogs = async (id, executionId) => {
  return await request('get', `/echecks/payrolls/${id}/logs/${executionId}`);
};

export const setEchecksPayrollPendingAction = async (id, pendingAction) => {
  return await request(
    'post',
    `/echecks/payrolls/${id}/action/${pendingAction}`
  );
};

export const diagnosticsSftpListDirectory = async ({
  credentials,
  directory
}) => {
  return await request('post', '/diagnostics/sftp/list-dir', null, {
    credentials,
    directory
  });
};

export const diagnosticsSftpGetFile = async ({ credentials, path }) => {
  return await request('post', '/diagnostics/sftp/get-file', null, {
    credentials,
    path
  });
};

export const getEchecksAuthStatus = async ({ companyId, environment }) => {
  return await request(
    'get',
    `/echecks/echecks-api/${companyId}/request-token/${environment}`
  );
};

export const echecksGenerateAuthToken = async ({ companyId, environment }) => {
  return await request(
    'post',
    `/echecks/echecks-api/${companyId}/request-token/${environment}/generate`
  );
};

export const echecksVerifyAuthToken = async ({ companyId, environment }) => {
  return await request(
    'post',
    `/echecks/echecks-api/${companyId}/request-token/${environment}/verify`
  );
};

export const diagnosticsSftpDelete = async ({ credentials, path }) => {
  return await request('post', '/diagnostics/sftp/delete', null, {
    credentials,
    path
  });
};

export const diagnosticsSftpUploadFile = async ({
  credentials,
  path,
  contents
}) => {
  return await request('post', '/diagnostics/sftp/upload-file', null, {
    credentials,
    path,
    contents
  });
};

export const diagnosticsSftpRename = async ({ credentials, path, newPath }) => {
  return await request('post', '/diagnostics/sftp/rename', null, {
    credentials,
    path,
    newPath
  });
};

export const fetchComments = async (jobId) => {
  return request('get', `/comments/${jobId}`);
};

export const createComment = async (comment, cancelToken) => {
  return request('post', '/comments', null, comment, cancelToken);
};

export const patchComment = async (id, comment) => {
  return request('patch', `/comments/${id}`, null, comment);
};

export const deleteComment = async (id, updater) => {
  return request('delete', `/comments/${id}`, null, updater);
};

export const getAvailable1PasswordFields = async () => {
  return request('get', '/1password/fields');
};

export const getAvailable1PasswordItems = async () => {
  return request('get', '/1password/items');
};

export const getAbgIntegrations = async () => {
  return request('get', '/abg');
};

export const getAbgIntegration = async (id) => {
  return request('get', `/abg/${id}`);
};

export const getAbgIntegrationSchema = async () => {
  return request('get', '/abg/meta/schema');
};

export const createAbgIntegration = async (body) => {
  return request('post', '/abg', null, body);
};

export const updateAbgIntegration = async (id, newAbg) => {
  return request('put', `/abg/${id}`, null, newAbg);
};

export const deleteAbgIntegration = async (id) => {
  return request('delete', `/abg/${id}`);
};

export const getPodsHealth = (id) => {
  return request('get', `/health/pods/${id}`);
};

export const getMongosHealth = () => {
  return request('get', '/health/mongo/');
};
