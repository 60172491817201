import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { formatFailedEmailSentAt } from '../../../../helpers';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Modal,
  styled
} from '@mui/material';
import { ActionCompletedSnackbar } from 'components/ActionCompletedSnackbar'

const Content = styled(CardContent)({
  padding: 0
});

const Actions = styled(CardActions)({
  justifyContent: 'flex-end'
});

const Inner = styled('div')({
  minWidth: 1050
});

const Paper = styled('div')(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.palette.shadows.primary,
  padding: theme.spacing(4, 4, 4),
  width: '85%',
  height: '90%',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

const FailedEmailsTable = (props) => {
  const {
    className,
    failedEmails,
    setFailedEmails,
    selectedFailedEmails,
    setSelectedFailedEmails,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageCount,
    ...rest
  } = props;

  const [state, setState] = useState({
    emailDataOpen: false,
    values: {
      _id: '',
      to: '{ }',
      from: '{ }',
      subject: '',
      customer: '',
      integration: '',
      messages: '{ }',
      messageTime: '',
      messageDate: '',
      messageUuid: ''
    }
  });
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleSelectAll = (event) => {
    const { failedEmails } = props;

    let selectedFailedEmails;

    if (event.target.checked) {
      selectedFailedEmails = failedEmails.map((email) => email._id);
    } else {
      selectedFailedEmails = [];
    }
    setSelectedFailedEmails(selectedFailedEmails);
  };

  const closeEmailData = () => {
    setState({
      emailDataOpen: false,
      values: {
        _id: '',
        to: '{ }',
        from: '{ }',
        subject: '',
        customer: '',
        integration: '',
        messages: '{ }',
        messageTime: '',
        messageDate: '',
        messageUuid: ''
      }
    });
  };

  const openEmailData = async (id, emailData) => {
    try {
      setState({
        ...state,
        emailDataOpen: true,
        values: {
          _id: id,
          to: emailData.to,
          from: emailData.from,
          subject: emailData.dynamic_template_data.subject,
          customer: emailData.dynamic_template_data.customer_identifier,
          integration: emailData.dynamic_template_data.integration_identifier,
          messages: emailData.dynamic_template_data.messages,
          messageTime: emailData.dynamic_template_data.message_time,
          messageDate: emailData.dynamic_template_data.message_date,
          messageUuid: emailData.dynamic_template_data.uuid
        }
      });
    } catch (err) {
      console.error(err);
      setState({
        ...state
      });
      setShowSnackbar(true);
    }
  };

  const handleSelectOne = (_, id) => {
    const selectedIndex = selectedFailedEmails.indexOf(id);
    let newselectedFailedEmails = [];

    if (selectedIndex === -1) {
      newselectedFailedEmails = newselectedFailedEmails.concat(
        selectedFailedEmails,
        id
      );
    } else if (selectedIndex === 0) {
      newselectedFailedEmails = newselectedFailedEmails.concat(
        selectedFailedEmails.slice(1)
      );
    } else if (selectedIndex === selectedFailedEmails.length - 1) {
      newselectedFailedEmails = newselectedFailedEmails.concat(
        selectedFailedEmails.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newselectedFailedEmails = newselectedFailedEmails.concat(
        selectedFailedEmails.slice(0, selectedIndex),
        selectedFailedEmails.slice(selectedIndex + 1)
      );
    }

    setSelectedFailedEmails(newselectedFailedEmails);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(event.target.value);
  };

  return (
    <Card {...rest} className={className}>
      <Content>
        <PerfectScrollbar>
          <Inner>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedFailedEmails.length === failedEmails.length
                      }
                      color="primary"
                      indeterminate={
                        selectedFailedEmails.length > 0 &&
                        selectedFailedEmails.length < failedEmails.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Integration ID</TableCell>
                  <TableCell>Messages</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>UUID</TableCell>
                  <TableCell>Sent At</TableCell>
                  <TableCell>Email Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {failedEmails.map((email) => (
                  <TableRow
                    hover
                    key={email._id}
                    selected={selectedFailedEmails.indexOf(email._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedFailedEmails.indexOf(email._id) !== -1}
                        color="primary"
                        onChange={(event) => handleSelectOne(event, email._id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{email.message.service}</TableCell>
                    <TableCell>{email.message.id}</TableCell>
                    <TableCell>{email.message.serviceId}</TableCell>
                    <TableCell>{email.message.messageArray}</TableCell>
                    <TableCell>{email.message.status}</TableCell>
                    <TableCell>{email.message.uuid}</TableCell>
                    <TableCell>
                      {formatFailedEmailSentAt(new Date(email.sentAt))}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="showData"
                        onClick={() =>
                          openEmailData(email._id, email.emailData)
                        }
                      >
                        <ArrowDropUp />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Modal onClose={closeEmailData} open={state.emailDataOpen}>
              <Paper>TODO: {state.values._id}</Paper>
            </Modal>
          </Inner>
        </PerfectScrollbar>
      </Content>
      <Actions>
        <TablePagination
          component="div"
          count={pageCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Actions>
      <ActionCompletedSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        onExited={() => setState(state => ({...state, errorMessage: ''}))}
        errorMessage={state.errorMessage}
      />
    </Card>
  );
};

FailedEmailsTable.propTypes = {
  failedEmails: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      emailData: PropTypes.object,
      message: PropTypes.object
    })
  ),
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  selectedFailedEmails: PropTypes.arrayOf(PropTypes.string),
  setFailedEmails: PropTypes.func,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSelectedFailedEmails: PropTypes.func
};

export default FailedEmailsTable;
