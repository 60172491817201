import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout, PrivateRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  JobList as JobListView,
  AdminJobs as AdminJobsView,
  RetiredJobs as RetiredJobsView,
  WeekJobExecutionsList,
  AllTimeJobExecutionsList,
  JobExecutionDetail as JobExecutionDetailView,
  IntegrationList as IntegrationListView,
  FailedEmailList as FailedEmailListView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  NotAllowed as NotAllowedView,
  EchecksConfigList as EchecksConfigsView,
  CreateEchecksConfig,
  EditEchecksConfig,
  ViewEchecksPayrolls,
  EchecksPayrollDetail,
  EchecksExecutionDetail,
  AbgIntegrationList,
  AbgIntegrationCreate,
  AbgIntegrationDetail
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <PrivateRouteWithLayout
        component={DashboardView}
        componentName="DashboardView"
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={JobListView}
        componentName="JobListView"
        layout={MainLayout}
        path="/jobs"
      />
      <PrivateRouteWithLayout
        component={AdminJobsView}
        componentName="AdminJobsView"
        layout={MainLayout}
        path="/admin_jobs"
      />
      <PrivateRouteWithLayout
        component={RetiredJobsView}
        componentName="RetiredJobsView"
        layout={MainLayout}
        path="/retired_jobs"
      />
      <PrivateRouteWithLayout
        component={WeekJobExecutionsList}
        componentName="WeekJobExecutionsList"
        exact
        layout={MainLayout}
        path="/job_executions"
      />
      <PrivateRouteWithLayout
        component={AllTimeJobExecutionsList}
        componentName="AllTimeJobExecutionsList"
        exact
        layout={MainLayout}
        path="/job_executions/all"
      />
      <PrivateRouteWithLayout
        component={JobExecutionDetailView}
        componentName="JobExecutionDetailView"
        layout={MainLayout}
        path="/job_executions/:uuid"
      />
      <PrivateRouteWithLayout
        component={IntegrationListView}
        componentName="IntegrationListView"
        exact
        layout={MainLayout}
        path="/integrations"
      />
      <PrivateRouteWithLayout
        component={AbgIntegrationList}
        componentName="AbgIntegrationList"
        exact
        layout={MainLayout}
        path="/abg"
      />
      <PrivateRouteWithLayout
        component={AbgIntegrationCreate}
        componentName="AbgIntegrationCreate"
        exact
        layout={MainLayout}
        path="/abg/new"
      />
      <PrivateRouteWithLayout
        component={AbgIntegrationDetail}
        componentName="AbgIntegrationDetail"
        exact
        layout={MainLayout}
        path="/abg/:integrationId"
      />
      <PrivateRouteWithLayout
        component={FailedEmailListView}
        componentName="FailedEmailListView"
        exact
        layout={MainLayout}
        path="/failedemails"
      />
      <PrivateRouteWithLayout
        component={CreateEchecksConfig}
        componentName="CreateEchecksConfig"
        exact
        layout={MainLayout}
        path="/echecks/config/new"
      />
      <PrivateRouteWithLayout
        component={EditEchecksConfig}
        componentName="EditEchecksConfig"
        exact
        layout={MainLayout}
        path="/echecks/config/:companyId"
      />
      <PrivateRouteWithLayout
        component={EchecksConfigsView}
        componentName="EchecksConfigsView"
        exact
        layout={MainLayout}
        path="/echecks/config"
      />
      <PrivateRouteWithLayout
        component={EchecksExecutionDetail}
        componentName="EchecksExecutionDetail"
        exact
        layout={MainLayout}
        path="/echecks/payrolls/:payrollId/execution/:executionId"
      />
      <PrivateRouteWithLayout
        component={EchecksPayrollDetail}
        componentName="EchecksPayrollDetail"
        exact
        layout={MainLayout}
        path="/echecks/payrolls/:payrollId"
      />
      <PrivateRouteWithLayout
        component={ViewEchecksPayrolls}
        componentName="ViewEchecksPayrolls"
        exact
        layout={MainLayout}
        path="/echecks/payrolls"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotAllowedView}
        exact
        layout={MinimalLayout}
        path="/not-allowed"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
