/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  Slide,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@mui/material';
import { testJob, testSftp } from '_services/ApiService';
import SecretField from './SecretField';
import { getConfigSchema } from '_services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 *
 * @param {{
 * handleClose: () => {},
 * testStatus:string
 * }} props
 * @returns
 */
const DialogMessage = (props) => {
  const { testStatus, handleClose } = props;

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        onClose={handleClose}
        open
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {testStatus ? <p>{testStatus}</p> : <CircularProgress />}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 *
 * @param {{config:{
 *  apiKey:string | { type:string, value:string,iv:string},
 *  username:string,
 *  companyName:string,
 *  password:string | { type:string, value:string,iv:string}
 *  baseUrl:string
 * companyTarget: string
 * jobType: string
 * }
 * }} props
 *
 * @returns
 */
export const TestKronos = (props) => {
  const {
    config,
    config: { jobType }
  } = props;

  const [testStatus, setTestStatus] = useState('');
  const [kronosConfig, setKronosConfig] = useState({
    ...config
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTestStatus('');
  };

  /**
   *
   * @param {React.KeyboardEvent} e
   */
  const handleChanges = (e) => {
    setKronosConfig({
      ...kronosConfig,
      [e.target.name]: e.target.value
    });
  };

  /**
   *
   * @param {string} field
   * @returns
   */
  const updateAField = (field) => {
    return (updateValue) => {
      const updateField =
        typeof updateValue === 'object'
          ? {
            ...kronosConfig[field],
            ...updateValue
          }
          : updateValue;

      setKronosConfig({
        ...kronosConfig,
        [field]: updateField
      });
    };
  };

  const validateHeaders = (schema, headers) => {
    const accepted = headers.filter((repRow) => schema.includes(repRow));
    const unknown = headers.filter((repRow) => !schema.includes(repRow));
    const missing = schema.filter((schemRow) => !headers.includes(schemRow));
    return { accepted, unknown, missing };
  };

  const createList = ({ accepted, missing, unknown }) => {
    return (
      <div>
        <p>
          <strong>Accepted Fields: </strong>
        </p>
        <ul>
          {accepted.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        <hr />
        <p>
          <strong>Missing Fields: </strong>
        </p>
        <ul>
          {missing.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        <hr />
        <p>
          <strong>Unknown Fields: </strong>
        </p>
        <ul>
          {unknown.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    );
  };

  const SendTestCase = async () => {
    setOpen(true);
    try {
      const { kronosSchema } = await getConfigSchema(jobType);

      if (!kronosSchema) {
        setTestStatus('Job has no kronos validation fields defined');
        return;
      }

      const { type, message, headers } = await testJob(kronosConfig);

      if (type === 'error') {
        setTestStatus(`Error reading report: ${message}`);
      } else {
        const result = validateHeaders(kronosSchema, headers);
        const fullList = createList(result);
        setTestStatus(fullList);
      }
    } catch (error) {
      setTestStatus(error.message);
    }
  };

  return (
    <>
      {open ? (
        <DialogMessage handleClose={handleClose} testStatus={testStatus} />
      ) : (
        <></>
      )}

      <CardHeader title="Test kronos" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="baseUrl"
              onChange={handleChanges}
              required
              value={kronosConfig.baseUrl}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="companyTarget"
              onChange={handleChanges}
              placeholder="company target"
              required
              value={kronosConfig.companyTarget}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="company name"
              name="companyName"
              onChange={handleChanges}
              placeholder="companyName"
              required
              value={kronosConfig.companyName}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="username"
              name="username"
              onChange={handleChanges}
              placeholder="username"
              required
              value={kronosConfig.username}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Report id"
              name="reportId"
              onChange={handleChanges}
              placeholder="Report id"
              required
              value={kronosConfig.reportId}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <SecretField
              label="Api key"
              secret={config.apiKey}
              setState={updateAField('apiKey')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <SecretField
              label="Password"
              secret={config.password}
              setState={updateAField('password')}
            />
          </Grid>
        </Grid>

        <Button color="primary" onClick={SendTestCase} variant="contained">
          Test report
        </Button>
      </CardContent>
    </>
  );
};

/**
 *
 * @param {{config:{
 * username:string,
 * password:string | {type:string,value:string,iv:string}
 * host:string,
 * port:number
 * },
 * directory:string;
 * }} props
 */
export const TestSftp = (props) => {
  const { config, directory } = props;
  const [testStatus, setTestStatus] = useState('');
  const [sftpConfig, setSftpConfig] = useState({ ...config, directory });
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setTestStatus('');
  };

  /**
   *
   * @param {React.KeyboardEvent} e
   */
  const handleChanges = (e) => {
    setSftpConfig({
      ...sftpConfig,
      [e.target.name]: e.target.value
    });
  };

  /**
   *
   * @param {string} field
   * @returns
   */
  const updateAField = (field) => {
    return (updateValue) => {
      const updateField =
        typeof updateValue === 'object'
          ? {
            ...sftpConfig[field],
            ...updateValue
          }
          : updateValue;

      setSftpConfig({
        ...sftpConfig,
        [field]: updateField
      });
    };
  };

  const SendTestCase = async () => {
    setOpen(true);
    try {
      await testSftp(sftpConfig);
      setTestStatus('The passed successfully');
    } catch (error) {
      setTestStatus(error.message);
    }
  };

  return (
    <>
      {open ? (
        <DialogMessage handleClose={handleClose} testStatus={testStatus} />
      ) : (
        <></>
      )}

      <CardHeader title="Test sftp" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="username"
              onChange={handleChanges}
              required
              value={sftpConfig.username}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="directory"
              onChange={handleChanges}
              required
              value={sftpConfig.directory}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="host"
              onChange={handleChanges}
              required
              value={sftpConfig.host}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="port"
              onChange={handleChanges}
              required
              value={sftpConfig.port}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <SecretField
              label="password"
              secret={config.password}
              setState={updateAField('password')}
            />
          </Grid>
        </Grid>

        <Button color="primary" onClick={SendTestCase} variant="contained">
          Test report
        </Button>
      </CardContent>
    </>
  );
};
