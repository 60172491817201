import React, { useEffect } from 'react';
import { msalConfig } from '../authConfig';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import DialogMessage from './DialogMessage';
import { authenticateWithMicrosoft } from '_services/ApiService';

/**
 *
 * @param {{location:{search:string}}} props
 * @returns
 */
const SignInButton = (props) => {
  const {
    auth: { clientId, redirectUri, tenantId },
    scopes
  } = msalConfig;
  const redirect = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scopes}`;
  const code = new URLSearchParams(props.location.search).get('code');
  const { history, className } = props;
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setIsLoadingAuth(false);
  };

  useEffect(() => {
    const authenticate = async () => {
      if (code) {
        setIsLoadingAuth(true);
        try {
          await authenticateWithMicrosoft(code, history);
          history.push('/');
        } catch (error) {
          setMessage(error.message);
        }
      }
    };

    authenticate();
  }, [code]);

  if (isLoadingAuth) {
    return <DialogMessage handleClose={handleClose} message={message} />;
  }

  return (
    <Button
      className={className}
      color="primary"
      fullWidth
      onClick={() => {
        window.location.href = redirect;
      }}
      size="large"
      type="submit"
      variant="contained"
    >
      Sign in with Microsoft
    </Button>
  );
};

export default withRouter(SignInButton);
