import React, { useState } from 'react';
import CreateJob from './CreateJob';
import CreateJobAdvanced from './CreateJobAdvanced';
import { useJobsContext } from 'components/JobList';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TestKronos } from './TestCredentials';
import { TestSftp } from './TestSFTP';
import { useModalContext } from './ModalContext';
import { is } from 'ramda';

// Try to generate the props for a testing component
// If we can't, we return a null component to signal that the component is not available
function testTab({ component: Component, calculateProps }) {
  try {
    const props = calculateProps();
    return <Component {...props} />;
  } catch (e) {
    if (process.env.NODE_ENV === 'production') {
      console.error('Unable to construct props for', Component, 'because', e);
    }
    return null;
  }
}

const TabPanel = ({ value, index, children }) => {
  return (
    <div
      hidden={value !== index}
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 250px)'
      }}
    >
      {value === index && children}
    </div>
  );
};

function CreateJobComponent(props) {
  const { formState } = useModalContext();
  const { config, jobType } = formState.values;

  const [tab, setTab] = useState(0);
  const { readonly } = useJobsContext();

  const testKronosComponent = testTab({
    component: TestKronos,
    calculateProps: () => {
      const parsedConfig = JSON.parse(config);

      const reportId =
        parsedConfig.reportID ??
        parsedConfig.reportDetails?.reportID ??
        parsedConfig.reportDetails?.demographicReport ??
        parsedConfig.report ??
        0;

      return {
        config: {
          ...parsedConfig.kronos,
          companyTarget: parsedConfig.companyName,
          reportId,
          jobType
        }
      };
    }
  });

  const testSftpComponent = testTab({
    component: TestSftp,
    calculateProps: () => {
      const parsedConfig = JSON.parse(config);
      const mepImportDirectory = '/migrated/wurk/OUT';
      const isMepImport = jobType === 'mep-import';
      const defaultDirectory =
        parsedConfig.directory ?? parsedConfig.sourceDirectory;
      return {
        initialHost: parsedConfig.sftp.host,
        initialPort: parsedConfig.sftp.port,
        initialUsername: parsedConfig.sftp.username,
        initialPassword: parsedConfig.sftp.password,
        initialDirectory: isMepImport ? mepImportDirectory : defaultDirectory
      };
    }
  });

  return (
    <>
      <Tabs onChange={(evt, newValue) => setTab(newValue)} value={tab}>
        <Tab label="Configuration" />
        {!readonly && <Tab label="Configuration (Advanced)" />}
        {!readonly && (
          <Tab disabled={!testKronosComponent} label="Test Kronos" />
        )}
        {!readonly && <Tab disabled={!testSftpComponent} label="Test SFTP" />}
      </Tabs>
      <TabPanel index={0} value={tab}>
        <CreateJob {...props} />
      </TabPanel>
      {!readonly && (
        <>
          <TabPanel index={1} value={tab}>
            <CreateJobAdvanced />
          </TabPanel>
          <TabPanel index={2} value={tab}>
            {testKronosComponent}
          </TabPanel>
          <TabPanel index={3} value={tab}>
            {testSftpComponent}
          </TabPanel>
        </>
      )}
    </>
  );
}

export default CreateJobComponent;
