import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Collapse, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableView from './TableView';
import { JSONView, CSVView, JSONText, UnformattedView } from './FormatViews';
import { downloadFile } from '../../../helpers';
import TogleableIconButton from './TogleableIconButton';

const TabPanel = ({ value, index, children }) => {
  return (
    <div
      hidden={value !== index}
      style={{ overflow: 'scroll', height: 'calc(100vh - 250px)' }}
    >
      {value === index && children}
    </div>
  );
};

const ShowData = ({ data }) => {
  const { value, fileName, name } = data;
  const [tab, setTab] = useState(0);

  const exportToCsv = (e) => {
    e.preventDefault();

    downloadFile({
      data: value,
      fileName: fileName || name,
      fileType: 'text/csv'
    });
  };

  const exportToJson = (e) => {
    e.preventDefault();

    downloadFile({
      data: JSON.stringify(value),
      fileName: fileName || name,
      fileType: 'text/json'
    });
  };

  return (
    <>
      <Tabs onChange={(evt, newValue) => setTab(newValue)} value={tab}>
        <Tab label="json" />
        <Tab label="table" />
        <Tab label="table from csv" />
        <Tab label="Text" />
        <Tab label="JSON Text" />
        {typeof value !== 'string' ? (
          <Button
            color="primary"
            onClick={exportToJson}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            variant="outlined"
          >
            Download JSON
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={exportToCsv}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            variant="outlined"
          >
            Download CSV
          </Button>
        )}
      </Tabs>
      <TabPanel index={0} value={tab}>
        <JSONView value={value} />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <TableView value={value} />
      </TabPanel>
      <TabPanel index={2} value={tab}>
        <CSVView value={value} />
      </TabPanel>
      <TabPanel index={3} value={tab}>
        <JSONText value={value} />
      </TabPanel>
      <TabPanel index={4} value={tab}>
        <UnformattedView value={value} />
      </TabPanel>
    </>
  );
};

const ShowDataWithLoader = ({ data, ...rest }) => {
  const value = data.value;
  const [realValue, setRealValue] = useState(value);
  const [loading, setLoading] = useState(typeof value === 'function');
  const [error, setError] = useState(null);
  useEffect(() => {
    if (typeof value === 'function') {
      async function load() {
        setLoading(true);
        try {
          const rv = await value();
          if (rv === undefined) {
            throw new Error('Function for value returned undefined');
          }
          setRealValue(rv);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
      load();
    }
  }, [value]);
  if (loading) {
    return <>loading...</>;
  }
  if (error) {
    return <>Error: {error.toString()}</>;
  }
  const params = { ...rest, data: { ...data, value: realValue } };
  return <ShowData {...params} />;
};

const DebugInfoLogLine = ({ line }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { type, data, ...rest } = line;

  // If fileName, then no need to render the type.
  let logContent = `${type.toUpperCase()} ${data.name} ${
    data.fileName ? ` | ${data.fileName}` : ''
  } ${!data.fileName && data.type ? ` | ${data.type}` : ''}`;

  return (
    <Card style={{ margin: '15px' }}>
      <CardHeader
        title={
          <>
            <TogleableIconButton
              aria-expanded={expanded}
              aria-label="show more"
              onClick={handleExpandClick}
              toggled={expanded}
            >
              <ExpandMoreIcon />
            </TogleableIconButton>
            {logContent}
          </>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ShowDataWithLoader data={data} />
      </Collapse>
    </Card>
  );
};

export default DebugInfoLogLine;
