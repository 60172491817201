import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { getEchecksPayroll, getEchecksLogs } from '../../_services/ApiService';
import LogsViewer from '../../components/LogsViewer';
import Loader from '../../components/Loader';

export function EchecksExecutionDetail({ match }) {
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    setLoadingLogs(true);
    getEchecksLogs(match.params.payrollId, match.params.executionId)
      .then(setLogs)
      .finally(() => setLoadingLogs(false));
  }, [match.params.payrollId, match.params.executionId]);

  const [payroll, setPayroll] = useState();
  const [loadingPayroll, setLoadingPayroll] = useState(false);
  useEffect(() => {
    setLoadingPayroll(true);
    getEchecksPayroll(match.params.payrollId)
      .then(setPayroll)
      .then(() => setLoadingPayroll(false))
      .catch((e) => {
        console.log(e.error);
        alert(e.error);
      });
  }, [match.params.payrollId]);

  return (
    <>
      {(loadingLogs || loadingPayroll) && <Loader />}
      <Card style={{ margin: '15px' }}>
        <CardHeader title="Payroll" />
        <CardContent>
          {payroll && (
            <ul>
              <li>
                Company: {payroll.company.shortName} ({payroll.company.id})
              </li>
              <li>
                Payroll: {payroll.payroll.name} ({payroll.payrollId})
              </li>
              <li>Finalized on: {payroll.payroll.finalizedDate}</li>
              <li>
                EIN: {payroll.payroll.ein.number} - {payroll.payroll.ein.name}
              </li>
              <li>
                Pending action:{' '}
                {payroll.status.pendingAction || 'No pending action'}
              </li>
            </ul>
          )}
        </CardContent>
      </Card>
      <Card style={{ margin: '15px' }}>
        <CardHeader title="Upload" />
        <CardContent>
          {payroll && (
            <ul>
              <li>ID: {match.params.executionId}</li>
              {Object.entries(payroll.uploads[match.params.executionId]).map(
                ([key, value]) => (
                  <li>
                    {key}: {value}
                  </li>
                )
              )}
            </ul>
          )}
        </CardContent>
      </Card>
      <LogsViewer
        logs={logs}
        startTime={
          payroll && payroll.uploads[match.params.executionId].startDate
        }
      />
    </>
  );
}
