import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  styled
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HealthItem from './HealthItem';
import { InlineLoader } from 'components/Loader/inline';
import { getMongosHealth, getPodsHealth } from '_services/ApiService';

const Root = styled(Card)({
  height: '100%'
});

const Header = styled(CardHeader)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1.5)
}));

const Content = styled(CardContent)({
  height: '45vh',
  overflowY: 'auto'
});

function HealthCheck() {
  const [checks, setChecks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function getHealthChecks() {
    const mongo = await getMongosHealth();
    const charlotte = await getPodsHealth('charlotte');
    const charJobs = await getPodsHealth('charlotte-jobs');
    const polling = await getPodsHealth('charlotte-polling');
    const webHook = await getPodsHealth('charlotte-webhook');
    return [
      {
        name: 'Charlotte Pod',
        state: charlotte.isHealthy,
        msg: charlotte.message
      },
      {
        name: 'Charlotte Jobs',
        state: charJobs.isHealthy,
        msg: charJobs.message
      },
      {
        name: 'Charlotte Polling',
        state: polling.isHealthy,
        msg: polling.message
      },
      {
        name: 'Charlotte Webhook',
        state: webHook.isHealthy,
        msg: webHook.message
      },
      {
        name: 'Mongo',
        state: mongo.isHealthy,
        msg: mongo.message
      }
    ];
  }

  async function loadCheck() {
    setLoading(true);
    setError(null);
    try {
      const healthChecks = await getHealthChecks();
      setChecks(healthChecks);
      setLoading(false);
    } catch (e) {
      setChecks([]);
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadCheck();
  }, []);

  function allChecksMsg() {
    const allGood = checks?.every(({ state }) => state);
    if (!allGood) return 'Some Systems Down ❌';
    return 'All Systems Operational ✅';
  }

  function mapChecks() {
    return checks?.map((check) => (
      <Grid key={check.name} xs={6}>
        <HealthItem check={check} />
      </Grid>
    ));
  }

  const checksList = (
    <Content>
      <Grid container item spacing={1}>
        {mapChecks()}
      </Grid>
    </Content>
  );

  return (
    <Grid item xs={6}>
      <Root>
        <Header
          action={
            <IconButton onClick={loadCheck} size="small">
              <RefreshIcon />
            </IconButton>
          }
          title={`System Status: ${allChecksMsg()}`}
        />
        <Divider />
        {loading && <InlineLoader />}
        {error && <>error</>}
        {checksList}
      </Root>
    </Grid>
  );
}

export default HealthCheck;
