import React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  styled
} from '@mui/material';

const ErrorCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.warning.ultralight
}));

export function InlineError({ error, component }) {
  return (
    <ErrorCard>
      <CardHeader title="Error" />
      <CardContent>
        <Typography component={component}>
          {error.message || `${error}`}
        </Typography>
      </CardContent>
    </ErrorCard>
  );
}

InlineError.defaultProps = {
  component: 'h2'
};

export default InlineError;
