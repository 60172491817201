import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthorized, isLoggedIn } from '_services/utils';

const PrivateRouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    auth,
    componentName,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isLoggedIn()) {
          // User's logged in
          if (isAuthorized(componentName)) {
            // And Authorized
            return (
              <Layout>
                <Component {...matchProps} auth={props.auth} />
              </Layout>
            );
          } else {
            // But not Authorized
            return <Redirect to="/not-allowed" />;
          }
        } else {
          return <Redirect to="/sign-in" />;
        }
      }}
    />
  );
};

export default PrivateRouteWithLayout;
