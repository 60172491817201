import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export function InlineLoader() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.1)',
        display: 'flex'
      }}
    >
      <CircularProgress style={{ margin: 'auto' }} />
    </div>
  );
}
