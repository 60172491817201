import React from 'react';
import { Box, Button } from '@mui/material';
import { downloadFile, createCsv } from 'helpers';
import { useJobsContext } from 'components/JobList';
import { scheduleDescription } from './CreateJob/CronDescription';
import { startedBy } from 'components/JobExecution/StartedBy';

export default function DownloadJobs() {
  const { jobs } = useJobsContext();

  const companyForJob = (job) => job.config?.companyName?.toUpperCase();
  const lastRunForJob = (date) => new Date(date).toLocaleString();

  const list = jobs.map((job) => {
    return {
      ...job,
      Name: job.jobName,
      Type: job.jobType,
      lastRunDate: lastRunForJob(job.lastRunDate),
      startedBy: startedBy(job),
      Customer: companyForJob(job),
      Schedule: scheduleDescription(job.schedule)
    };
  });

  const headers = [
    'Name',
    'Type',
    'lastRunDate',
    'startedBy',
    'Schedule',
    'Customer',
    'status',
    'attempts'
  ];

  const exportList = (e) => {
    const data = createCsv(list, headers);
    e.preventDefault();
    downloadFile({
      data,
      fileName: `Exported List - ${new Date().toDateString()}`,
      fileType: 'text/csv'
    });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Button
        color="primary"
        onClick={exportList}
        size="medium"
        variant="contained"
      >
        Export List
      </Button>
    </Box>
  );
}
