import React from 'react';
import { MenuItem, Select, CardHeader, TextField, Grid } from '@mui/material';

/**
 *
 * @param {{
 *  setState:()=>{},
 *  secret:string | { type:string, value:string, iv:string},
 *  label:string
 * }} props
 * @returns
 */
export default function SecretField({ secret, setState, label }) {
  const type = secret?.type ?? 'decrypted';

  /**
   *
   * @param {React.ChangeEvent<{value:string}} e
   */
  const updateFieldMode = (e) => {
    if (e.target.value === 'encrypted') {
      setState({
        type: 'encrypted',
        iv: '',
        value: ''
      });
    } else {
      setState('');
    }
  };

  return (
    <>
      <CardHeader title={label} />

      <Select onChange={updateFieldMode} value={type}>
        <MenuItem value="encrypted">Encrypted</MenuItem>
        <MenuItem value="decrypted">Decrypted</MenuItem>
      </Select>

      {type === 'encrypted' ? (
        <Grid>
          <TextField
            fullWidth
            name="iv"
            onChange={(e) => setState({ ...secret, iv: e.target.value })}
            placeholder="iv"
            required
            value={secret.iv}
            variant="outlined"
          />

          <TextField
            fullWidth
            name="value"
            onChange={(e) => setState({ ...secret, value: e.target.value })}
            placeholder="value"
            required
            value={secret.value}
            variant="outlined"
          />
        </Grid>
      ) : (
        <Grid>
          <TextField
            fullWidth
            name="apiKey"
            onChange={(e) => setState(e.target.value)}
            placeholder="value"
            required
            value={secret}
            variant="outlined"
          />
        </Grid>
      )}
    </>
  );
}
