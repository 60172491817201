import React from 'react';
import JobList from '../JobList';

// TODO: Find where to place all the commmon constants or types/ This type `RETIRED` is
// TODO: already in Charlotte-jobs:
const RETIRED = 'retired';
const ADMIN = 'admin';

function filter({ status, jobName }) {
  const isRetired = status === RETIRED;
  const isAdmin = jobName.toLowerCase().includes(ADMIN);
  return isRetired && !isAdmin;
}

const RetiredJobs = (props) => {
  return <JobList {...props} filter={filter} readonly />;
};

export default RetiredJobs;
