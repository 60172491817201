import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { Sidebar, Topbar, Footer } from './components';

const Root = styled('div')({
  height: '100%'
});

const MainChildren = styled('main')({
  height: '100%',
  marginLeft: 224,
  marginTop: 72
});

const Main = (props) => {
  const { children } = props;

  const [setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  return (
    <Root>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar open variant="persistent" />
      <MainChildren>
        {children}
        <Footer />
      </MainChildren>
    </Root>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
