import { IconButton, styled } from '@mui/material';

const TogleableIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'toggled'
})(({ theme, toggled }) => ({
  transform: toggled ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default TogleableIconButton;
