import React, { useState, useEffect } from 'react';
import { Grid, styled } from '@mui/material';
import { DsbPieChart, ExecutionList, GridEntry } from './components';
import { getTodaysExecutions } from '_services/ApiService';
import { InlineLoader } from 'components/Loader/inline';
import { jobResultType } from 'helpers/jobResultType';
import { useSearchParam } from 'helpers/useSearchParam';
import { HealthCheck } from './components/HealthCheck';

const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4)
}));

const ToggleButton = styled('button')({
  marginLeft: '0.5em',
  padding: '3px'
});

const Dashboard = () => {
  const [executions, setExecutions] = useState(null);
  const [error, setError] = useState(null);
  const showAdmin = false;
  const [showSuccessJobs, setShowSuccessJobs] = useSearchParam('success');
  const [showWarningJobs, setShowWarningJobs] = useSearchParam('warning');
  const [showErrorJobs, setShowErrorJobs] = useSearchParam('error');
  const [showDisabledJobs, setShowDisabledJobs] = useSearchParam('disabled');
  const [showOtherJobs, setShowOtherJobs] = useSearchParam('other');

  const loadExecutions = async () => {
    setExecutions(null);
    setError(null);
    try {
      const executions = await getTodaysExecutions();
      const noAdminExecutions = executions.filter(
        (job) => !job.jobType.startsWith('admin')
      );
      setExecutions(noAdminExecutions);
      setError(null);
    } catch (e) {
      setExecutions(null);
      setError(e.message);
    }
  };
  useEffect(() => {
    loadExecutions();
  }, []);

  const loading = executions === null && error === null;
  const filteredExecutions =
    executions &&
    executions
      .filter((execution) =>
        showAdmin && execution.jobType.startsWith('admin') ? showAdmin : true
      )
      .filter((execution) => {
        const showAll =
          !showSuccessJobs &&
          !showErrorJobs &&
          !showWarningJobs &&
          !showDisabledJobs &&
          !showOtherJobs;
        if (showAll) return true;
        const type = jobResultType(execution);
        switch (type) {
          case 'success':
            return showSuccessJobs;
          case 'error':
            return showErrorJobs;
          case 'warning':
            return showWarningJobs;
          case 'other':
            return showOtherJobs;
          case 'disabled':
            return showDisabledJobs;
        }
      });

  const toggleShowTypes = (type) => {
    switch (type) {
      case 'success':
        return setShowSuccessJobs((x) => !x);
      case 'error':
        return setShowErrorJobs((x) => !x);
      case 'warning':
        return setShowWarningJobs((x) => !x);
      case 'other':
        return setShowOtherJobs((x) => !x);
      case 'disabled':
        return setShowDisabledJobs((x) => !x);
    }
  };

  const includes = [
    !showSuccessJobs ? null : 'success',
    !showErrorJobs ? null : 'error',
    !showWarningJobs ? null : 'warning',
    !showDisabledJobs ? null : 'disabled',
    !showOtherJobs ? null : 'other'
  ]
    .filter((x) => x !== null)
    .map((type) => (
      <ToggleButton onClick={() => toggleShowTypes(type)}>{type}</ToggleButton>
    ));
  const includesComponent =
    includes.length === 0 ? <></> : <>(Includes{includes})</>;

  return (
    <Root container spacing={2}>
      <Grid item xs={6}>
        <GridEntry refresh={loadExecutions} title="Last Job Executions">
          {loading && <InlineLoader />}
          {executions && (
            <>
              <DsbPieChart
                executions={executions}
                refresh={loadExecutions}
                showTypes={{
                  success: showSuccessJobs,
                  error: showErrorJobs,
                  warning: showWarningJobs,
                  disabled: showDisabledJobs,
                  other: showOtherJobs
                }}
                toggleShowTypes={toggleShowTypes}
              />
            </>
          )}
          {error && <>{error}</>}
        </GridEntry>
      </Grid>
      <Grid item xs={6}>
        <GridEntry
          refresh={loadExecutions}
          title={<>Last Job Executions {includesComponent}</>}
        >
          {loading && <InlineLoader />}
          {filteredExecutions && (
            <ExecutionList
              executions={filteredExecutions}
              showTypes={{
                success: showSuccessJobs,
                error: showErrorJobs,
                warning: showWarningJobs,
                other: showOtherJobs,
                disabled: showDisabledJobs
              }}
              toggleShowTypes={toggleShowTypes}
            />
          )}
        </GridEntry>
      </Grid>
      <HealthCheck />
    </Root>
  );
};

export default Dashboard;
