import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { fetchComments } from '_services/ApiService';
import { CommentCard } from './CommentCard';
import { CommentsContext } from './CommentContext';
import { updateComment, removeComment } from './submit';

/**
 * Complete comments section, consisting of:
 * `Title`, `Comments list` & `Comment input`
 * @param {Object} props - props received
 * @param {string} props.jobId - Comments will be loaded for this job
 * @param {string} props.value - Context value for the input
 * @param {string} props.onChange - Action to be trigered when editing the input value
 * @param {string} props.readonly - When readonly, disable the comments box, you can still
 * edit or remove comments
 */
export const CommentSection = ({ jobId, value, onChange, readonly }) => {
  const [commentList, setcommentList] = useState([]);

  const compareDates = (first, second) => {
    const after = first.time > second.time;
    if (after) return -1;
    const before = first.time < second.time;
    return before ? 1 : 0;
  };

  const getComments = async () => {
    const list = await fetchComments(jobId);
    list.sort(compareDates);
    setcommentList(list);
  };

  const patchComment = async (update, current, commentId) => {
    await updateComment(update, current, commentId);
    await getComments();
  };

  const deleteComment = async (commentId) => {
    await removeComment(commentId);
    await getComments();
  };

  useEffect(() => {
    getComments();
  }, [jobId]);

  const Comments = () => {
    return commentList.map((comment, index) => (
      <CommentCard comment={comment} key={index} />
    ));
  };

  const NoComments = () => {
    return (
      <Card>
        <CardContent>
          <Typography component="p" gutterBottom variant="subtitle1">
            No comments yet
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const handleChange = (e) => onChange(e.target.value);
  return (
    <CommentsContext.Provider value={{ patchComment, deleteComment }}>
      <Typography component="div" gutterBottom variant="h5">
        Comments:
      </Typography>

      {commentList.length > 0 ? <Comments /> : <NoComments />}

      <Card>
        <CardContent>
          <TextField
            disabled={readonly}
            fullWidth
            label="Write a comment"
            margin="normal"
            minRows={2}
            multiline
            name="comment"
            onChange={handleChange}
            value={value}
            variant="filled"
          />
        </CardContent>
      </Card>
    </CommentsContext.Provider>
  );
};
