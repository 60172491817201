import React, { useState, useEffect } from 'react';
import { getAbgIntegrations, deleteAbgIntegration } from '_services/ApiService';
import { FullScreenLoader } from 'components/Loader';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Card,
  CardHeader,
  CardContent,
  styled
} from '@mui/material';

const Root = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3)
}));

const MainCardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const ActionButtonsTableCell = styled(TableCell)({
  display: 'flex',
  columnGap: '10px'
});

const AbgIntegrationList = () => {
  const [abgs, setAbgs] = useState(null);
  const [loading, setLoading] = useState(false);

  const refreshAbgs = async () => {
    setLoading(true);
    setAbgs(null);
    try {
      const result = await getAbgIntegrations();
      setAbgs(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshAbgs();
  }, []);

  async function deleteAbg(id) {
    if (confirm('Do you really want to delete ABG integration?')) {
      setLoading(true);
      try {
        await deleteAbgIntegration(id);
      } catch (e) {
        alert(e && e.toString());
      } finally {
        setLoading(false);
      }
      await refreshAbgs();
    }
  }

  return (
    <>
      {loading && <FullScreenLoader />}
      <Root>
        <CardHeader
          title={
            <MainCardHeader>
              <span>ABG Integrations</span>
              <Button color="primary" href="/abg/new" variant="contained">
                New ABG integration
              </Button>
            </MainCardHeader>
          }
        />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>JSON</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {abgs === null && (
                <TableRow>
                  <TableCell colspan={4}>...</TableCell>
                </TableRow>
              )}
              {abgs && abgs.length === 0 && (
                <TableRow>
                  <TableCell colspan={4}>No integrations found</TableCell>
                </TableRow>
              )}
              {abgs &&
                abgs.length > 0 &&
                abgs.map((abg) => (
                  <TableRow>
                    <TableCell>{abg._id}</TableCell>
                    <TableCell>{JSON.stringify(abg)}</TableCell>
                    <TableCell>{abg.kronos?.companyName}</TableCell>
                    <ActionButtonsTableCell>
                      <Button
                        color="primary"
                        href={`/abg/${abg._id}`}
                        variant="contained"
                      >
                        Edit
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => deleteAbg(abg._id)}
                        style={{ backgroundColor: 'red' }}
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </ActionButtonsTableCell>
                  </TableRow>
                ))}
              <TableRow />
            </TableBody>
          </Table>
        </CardContent>
      </Root>
    </>
  );
};

export default AbgIntegrationList;
