import React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useSearchParam } from 'helpers/useSearchParam';
import { useJobsContext } from 'components/JobList';

export default function SelectDay() {
  const { onDaySelectChange } = useJobsContext();
  const onChange = (event) => onDaySelectChange(event.target.value);

  const [day] = useSearchParam('day');
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  return (
    <Box sx={{ minWidth: 70 }}>
      <FormControl fullWidth>
        <InputLabel id="select-day-label">Day</InputLabel>
        <Select
          color="secondary"
          id="day-select"
          label="Day"
          labelId="select-day-label"
          onChange={onChange}
          value={day}
        >
          <MenuItem value="All">All dates</MenuItem>
          <MenuItem value={'*'}>Everyday</MenuItem>
          {days.map((day, index) => (
            <MenuItem key={day} value={index.toString()}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
