import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export function FullScreenLoader() {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: '0',
        left: '0',
        zIndex: '10000'
      }}
    >
      <div
        style={{
          top: 'calc(50vh - 20px)',
          position: 'absolute',
          left: 'calc(50vw - 20px)'
        }}
      >
        <CircularProgress style={{ margin: 'auto' }} />
      </div>
    </div>
  );
}

export default FullScreenLoader;
