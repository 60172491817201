import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { JobResult, jobResultStyle } from 'components/JobExecution/JobResult';
import moment from 'moment';
import { ExecutionRuntime } from 'components/JobExecution/ExecutionRuntime';
import { StartedBy } from 'components/JobExecution/StartedBy';

const ExecutionList = ({ executions }) => {
  return (
    <Table>
      <TableBody>
        {executions.map((e) => (
          <TableRow key={e._id} style={jobResultStyle(e.result)}>
            <TableCell>{e.jobName}</TableCell>
            <TableCell>
              {moment(e.startTime).format('HH:mm')}
              <br/>
              <StartedBy execution={e} />
            </TableCell>
            <TableCell>
              <JobResult result={e.result} />
            </TableCell>
            <TableCell>
              <ExecutionRuntime execution={e} />
            </TableCell>
            <TableCell>
              <IconButton
                component={RouterLink}
                to={`/job_executions/${e.uuid}`}
              >
                <ArrowForwardIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ExecutionList;
