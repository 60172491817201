import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { getEvents, runEvent } from '../../../../_services/ApiService';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  styled
} from '@mui/material';

const Content = styled(CardContent)({
  padding: 0
});

const Actions = styled(CardActions)({
  justifyContent: 'flex-end'
});

const Inner = styled('div')({
  minWidth: 1050
});

const EventsTable = (props) => {
  const {
    className,
    integrationId,
    setShowSnackbar,
    formState,
    setFormState,
    ...rest
  } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getEvents(integrationId, {
          page: page + 1,
          pageSize
        });
        setEvents(result.data);
        setPageCount(result.total);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [integrationId, page, pageSize]);

  const replayEent = async (eventId) => {
    try {
      eventId && integrationId && (await runEvent(eventId, integrationId));
    } catch (err) {
      console.error(err.message);
      setFormState({
        ...formState,
        errorMessage: err.message
      });
    }
    setShowSnackbar(true);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(event.target.value);
  };

  return (
    <Card {...rest} className={className}>
      <Content>
        <PerfectScrollbar>
          <Inner>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell>Integration ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date published</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow hover key={event._id}>
                    <TableCell>{event.event}</TableCell>
                    <TableCell>{event.integrationId}</TableCell>
                    <TableCell>{event.status}</TableCell>
                    <TableCell>
                      {moment(event.datePublished).format('YYYY-MM-DDTHH:mm')}
                    </TableCell>
                    <TableCell>{event.error}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="replay"
                        onClick={() => replayEent(event._id)}
                      >
                        <PlayIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Inner>
        </PerfectScrollbar>
      </Content>
      <Actions>
        <TablePagination
          component="div"
          count={pageCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Actions>
    </Card>
  );
};

EventsTable.propTypes = {
  formState: PropTypes.shape({
    errorMessage: PropTypes.string
  }),
  integrationId: PropTypes.string.isRequired,
  setFormState: PropTypes.func.isRequired,
  setShowSnackbar: PropTypes.func.isRequired
};

export default EventsTable;
