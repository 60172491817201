import React, { useState, useEffect } from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';
import { diagnosticsSftpListDirectory } from '_services/ApiService';
import SecretField from '../SecretField';
import { SFTPBrowser } from './browser';

/**
 *
 * @param {{
 * username:string;
 * password:string | {type:string,value:string,iv:string};
 * host:string;
 * port:number;
 * directory:string;
 * }} props
 */
export const TestSftp = (
  {
    initialHost,
    initialPort,
    initialUsername,
    initialPassword,
    initialDirectory
  },
  props
) => {
  const [host, setHost] = useState(initialHost);
  const [port, setPort] = useState(initialPort);
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState(initialPassword);
  const [directory, setDirectory] = useState(initialDirectory || '/');

  const [connectionStatus, setConnectionStatus] =
    React.useState('disconnected');

  useEffect(
    () => setConnectionStatus('disconnected'),
    [host, port, username, password, directory]
  );

  const toggleConnect = async () => {
    if (connectionStatus === 'connected') {
      return setConnectionStatus('disconnected');
    }
    if (connectionStatus === 'disconnected') {
      setConnectionStatus('connecting');
      try {
        const result = await diagnosticsSftpListDirectory({
          credentials: { host, port, username, password },
          directory
        });
        if (result.type === 'error') {
          throw new Error(result.message);
        } else {
          setConnectionStatus('connected');
        }
      } catch (e) {
        alert(e.message);
        setConnectionStatus('disconnected');
      }
      return;
    }
  };

  const formStyle =
    connectionStatus === 'connected'
      ? {
          maxHeight: '0px',
          overflow: 'hidden',
          transition: 'max-height 0.3s, opacity 0.4s',
          opacity: '0%'
        }
      : {
          maxHeight: '100%',
          overflow: 'hidden',
          transition: 'max-height: 0.3s'
        };

  return (
    <>
      <CardHeader title="Test sftp" />
      <Divider />
      <CardContent style={formStyle}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              required
              value={username}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="directory"
              onChange={(e) => setDirectory(e.target.value)}
              required
              value={directory}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="host"
              onChange={(e) => setHost(e.target.value)}
              required
              value={host}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="port"
              onChange={(e) => setPort(e.target.value)}
              required
              value={port}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <SecretField
              label="password"
              secret={password}
              setState={setPassword}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Button
          color="primary"
          onClick={toggleConnect}
          variant={
            connectionStatus === 'disconnected' ? 'contained' : 'outline'
          }
        >
          {connectionStatus === 'disconnected' ? (
            'Connect'
          ) : connectionStatus === 'connecting' ? (
            <>
              <CircularProgress />
              Connecting
            </>
          ) : (
            'Connected (click to disconnect)'
          )}
        </Button>
      </CardContent>
      <Divider />
      {connectionStatus === 'connected' && (
        <CardContent>
          <SFTPBrowser
            credentials={{ host, port, username, password }}
            initialDirectory={directory}
          />
        </CardContent>
      )}
    </>
  );
};
