import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Avatar, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'fit-content'
});

const WurkAvatar = styled(Avatar)({
  width: 60,
  height: 60
});

const Name = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textTransform: 'capitalize'
}));

const Profile = (props) => {
  const { className, ...rest } = props;

  const [user, setUser] = useState({
    name: '',
    email: '',
    avatar: ''
  });

  useEffect(() => {
    const token = jwtDecode(localStorage.getItem('token'));
    setUser({
      name: token.name,
      email: token.email,
      avatar: `https://ui-avatars.com/api/?name=${token.name}&length=1&background=ad86bd&color=fff&size=16`
    });
  }, []);

  return (
    <Root {...rest}>
      <WurkAvatar alt="Person" src={user.avatar} />
      <Name variant="h4">{user.name}</Name>
      <Typography variant="body2">{user.email}</Typography>
    </Root>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
