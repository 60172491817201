import React from 'react';
import PropTypes from 'prop-types';

export const StartedBy = ({ execution, hidePrefix }) => {
  const startedBy = execution.startedBy;
  if(startedBy === undefined) {
    // Old jobs don't have this field defined.
    // We don't really know if they were started by the system or by someone.
    // So we just return nothing.
    return <></>
  }
  if(startedBy === null) {
    // A job that is triggered by the schedulers will have a null started by
    return <>System schedule</>
  }
  return <>{!hidePrefix && <>Started by </>}<b>{startedBy}</b></>
};
StartedBy.propTypes = {
  execution: PropTypes.object.isRequired,
  hidePrefix: PropTypes.bool
}
StartedBy.defaultProps = {
  hidePrefix: false
}

export function startedBy(execution) {
  // This is the same as the component above, but without styles and HTML
  // so that we can use it as a field in a CSV
  const startedBy = execution.startedBy;
  if(startedBy === undefined) {
    return ""
  }
  if(startedBy === null) {
    return "System schedule"
  }
  return `Started by {startedBy}`
}
