import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  styled
} from '@mui/material';

const Content = styled(CardContent)(({}) => ({
  padding: 0
}));

const Inner = styled('div')({
  minWidth: 1050
});

const EchecksConfigTable = (props) => {
  const { className, configs, onDelete, ...rest } = props;

  const deleteConfig = (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete the configuration for company ID ${id}`
      )
    ) {
      onDelete(id);
    }
  };

  return (
    <Card {...rest} className={className}>
      <Content className={classes.content}>
        <PerfectScrollbar>
          <Inner>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>Reports</TableCell>
                  <TableCell>Associated EINs</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {configs && configs.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                      No configurations found.
                    </TableCell>
                  </TableRow>
                )}
                {configs &&
                  configs.length > 0 &&
                  configs.map((config) => (
                    <TableRow>
                      <TableCell>
                        {config?.company?.shortName} / {config?.company?.id}
                      </TableCell>
                      <TableCell>
                        {config.kronos &&
                          config.kronos.map((k) => (
                            <>{k.report?.savedReportId}</>
                          ))}
                      </TableCell>
                      <TableCell>
                        {config.eins &&
                          config.eins.map((ein) => (
                            <>
                              {ein.ein} - {ein.status}
                            </>
                          ))}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="secondary"
                          href={`/echecks/config/${config?.company?.id}`}
                          variant="contained"
                        >
                          Edit
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => deleteConfig(config?.company?.id)}
                          style={{ backgroundColor: 'red', marginLeft: '30px' }}
                          variant="contained"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Inner>
        </PerfectScrollbar>
      </Content>
    </Card>
  );
};

EchecksConfigTable.propTypes = {
  configs: PropTypes.array
};

export default EchecksConfigTable;
