import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  Typography,
  styled
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

const Root = styled(Card)({
  backgroundColor: '#DCB2F1',
  margin: '6px'
});

const Header = styled(Typography)({
  fontSize: '1.2em'
});

const SubHeader = styled(Typography)({
  fontSize: '1em'
});

function HealthItem({ check: { state, msg, name } }) {
  const theme = useTheme();

  const successIcon = <CheckBoxIcon color="success" />;
  const failIcon = <BrokenImageIcon sx={{ color: theme.palette.error.main }} />;
  const icon = state ? successIcon : failIcon;

  return (
    <Grid item xs={12}>
      <Root>
        <CardContent>
          <Header gutterBottom variant="h5">
            {name}
            <IconButton aria-label="settings" size="small">
              {icon}
            </IconButton>
          </Header>
          <SubHeader color="text.secondary">{msg}</SubHeader>
        </CardContent>
      </Root>
    </Grid>
  );
}

export default HealthItem;
