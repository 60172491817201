import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Topbar } from './components';

const Root = styled('div')({
  paddingTop: 64,
  height: '100%'
});

const Main = styled('main')({
  height: '100%'
});

const Minimal = (props) => {
  const { children } = props;

  return (
    <Root>
      <Topbar />
      <Main>{children}</Main>
    </Root>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
