import React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useSearchParam } from 'helpers/useSearchParam';
import { useJobsContext } from 'components/JobList';

export default function SelectJobType({}) {
  const { onTypeSelectChange, jobTypes } = useJobsContext();
  const [type] = useSearchParam('type');
  const onChange = (event) => onTypeSelectChange(event.target.value);

  return (
    <Box sx={{ minWidth: 75 }}>
      <FormControl fullWidth>
        <InputLabel id="select-type-label">Type</InputLabel>
        <Select
          color="secondary"
          id="type-select"
          label="Type"
          labelId="select-type-label"
          onChange={onChange}
          value={type}
        >
          <MenuItem value="All">All Types</MenuItem>
          {jobTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
