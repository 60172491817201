export default function createCsv(data, headers) {
  const csvRows = [];
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = [];

    for (const header of headers) {
      let value = row[header] !== undefined ? row[header] : '';
      if (typeof value === 'string' && value.includes(',')) {
        value = `"${value}"`;
      }
      values.push(value);
    }
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
}
