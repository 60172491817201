import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import { signOut } from '../../../../_services/utils';

const WurkAppBar = styled(AppBar)({
  boxShadow: 'none'
});

const Separator = styled('div')({
  flexGrow: 1
});

const SignOutButton = styled(IconButton)(({ theme }) => {
  theme.spacing(1);
});

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  return (
    <WurkAppBar {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo.png" />
        </RouterLink>
        <Separator />
        <SignOutButton color="inherit" onClick={signOut}>
          <InputIcon />
        </SignOutButton>
      </Toolbar>
    </WurkAppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
