import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { Typography, styled } from '@mui/material';
import { options } from './chart-options';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { jobResultType } from 'helpers/jobResultType';

const ChartContainer = styled('div')({
  position: 'relative',
  height: 'calc(100% - 105px - 20px)'
});

const Stats = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center'
}));

const Stat = styled('button')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(255,255,255, 0)',
  border: 0,
  cursor: 'pointer'
}));

/**
 * Pie chart used to show the percentage of `Success`, `Warn` or `Error`
 * in the executions
 * @param {Object} props - props received
 */
const DsbPieChart = ({ executions, refresh, showTypes, toggleShowTypes }) => {
  const theme = useTheme();

  let countByType = {
    success: 0,
    error: 0,
    warning: 0,
    disabled: 0,
    other: 0
  };

  executions.forEach((e) => {
    const type = jobResultType(e);
    countByType[type] += 1;
  });

  const data = [
    countByType.success,
    countByType.error,
    countByType.warning,
    countByType.disabled,
    countByType.other
  ];
  const total = data.reduce((a, b) => a + b);
  const percent = (d) => ((d * 100) / total).toFixed(1);

  const chartData = {
    datasets: [
      {
        data,
        backgroundColor: [
          showTypes.success
            ? theme.palette.success.main
            : theme.palette.success.ultralight,
          showTypes.error
            ? theme.palette.error.main
            : theme.palette.error.ultralight,
          showTypes.warning
            ? theme.palette.warning.main
            : theme.palette.warning.ultralight,
          showTypes.disabled
            ? theme.palette.disabled.main
            : theme.palette.disabled.ultralight,
          showTypes.other
            ? theme.palette.disabled.main
            : theme.palette.disabled.ultralight
        ],
        borderWidth: 2
      }
    ],
    labels: ['Success', 'Error', 'Warning', 'Disabled', 'Other']
  };

  const details = [
    {
      title: 'Success',
      value: percent(countByType.success),
      icon: <CheckCircleIcon />,
      color: showTypes.success
        ? theme.palette.success.main
        : theme.palette.success.ultralight,
      onClick: () => toggleShowTypes('success')
    },
    {
      title: 'Error',
      value: percent(countByType.error),
      icon: <ErrorIcon />,
      color: showTypes.error
        ? theme.palette.error.main
        : theme.palette.error.ultralight,
      onClick: () => toggleShowTypes('error')
    },
    {
      title: 'Warning',
      value: percent(countByType.warning),
      icon: <WarningIcon />,
      color: showTypes.warning
        ? theme.palette.warning.main
        : theme.palette.warning.ultralight,
      onClick: () => toggleShowTypes('warning')
    },
    {
      title: 'Disabled',
      value: percent(countByType.disabled),
      icon: <WarningIcon />,
      color: showTypes.disabled
        ? theme.palette.disabled.main
        : theme.palette.disabled.ultralight,
      onClick: () => toggleShowTypes('disabled')
    },
    {
      title: 'Other',
      value: percent(countByType.other),
      icon: <WarningIcon />,
      color: showTypes.other
        ? theme.palette.disabled.main
        : theme.palette.disabled.ultralight,
      onClick: () => toggleShowTypes('other')
    }
  ];

  const toggleShowTypesDoughnut = (event, elements) => {
    event.stopPropagation();
    if (elements.length) {
      toggleShowTypes(elements[0]._model.label.toLowerCase());
    }
  };

  return (
    <>
      <ChartContainer>
        <Doughnut
          data={chartData}
          options={{ ...options, onClick: toggleShowTypesDoughnut }}
        />
      </ChartContainer>
      <Stats>
        {details.map((detail) => (
          <Stat key={detail.title} onClick={detail.onClick}>
            <span style={{ color: detail.color }}>{detail.icon}</span>
            <Typography style={{ color: detail.color }} variant="h2">
              {detail.value}%
            </Typography>
            <Typography variant="body1">{detail.title}</Typography>
          </Stat>
        ))}
      </Stats>
    </>
  );
};
export default DsbPieChart;
