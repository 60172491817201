/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, ListItem, Button, colors, styled } from '@mui/material';

const NavItem = styled(ListItem)({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0
});

const NavButton = styled(Button)(({ theme }) => ({
  color: colors.blueGrey[800],
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  '&.active': {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    '& svg': {
      color: theme.palette.primary.contrastText
    },
    background: theme.palette.primary.main
  }
}));

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.icon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  return (
    <List {...rest} className={className}>
      {pages.map((page) => (
        <NavItem disableGutters key={page.title}>
          <NavButton component={CustomRouterLink} to={page.href}>
            <Icon>{page.icon}</Icon>
            {page.title}
          </NavButton>
        </NavItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
