import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from '@mui/material';

const ConfirmButton = ({
  onConfirm,
  openDialogButtonText,
  disabled,
  dialogTitle,
  dialogBody,
  acceptButtonText
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = () => {
    setDialogOpen(true);
  };
  const close = () => {
    setDialogOpen(false);
  };

  const runConfirm = async () => {
    await onConfirm();
    close();
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={open}
        disabled={disabled}
      >
        {openDialogButtonText}
      </Button>
      <Dialog open={dialogOpen} onClose={close}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">{dialogBody}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={runConfirm} color="primary" autoFocus>
            {acceptButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
ConfirmButton.propTypes = {
  // What to do when the user confirms the dialog
  onConfirm: PropTypes.func.isRequired,
  // What do we show in the button that opens the dialog
  openDialogButtonText: PropTypes.node.isRequired,
  // Shall we disable the button and the whole thing?
  disabled: PropTypes.bool,
  // Title of the dialog
  dialogTitle: PropTypes.node.isRequired,
  // Main text of the dialog
  dialogBody: PropTypes.node.isRequired,
  // Text of the "accept" button in the dialog
  acceptButtonText: PropTypes.node.isRequired
};

export default ConfirmButton;
