import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// useSearchParam
// Hook for managing a search param as if it was a value from useState
// The search is that part of the URL that goes ?foo=bar&baz=3
// It uses react-router, so don't manually set the URL so that react-router catches it
// It also synchronizes multiple instances, so you can use the same search param in multiple places
// Acceptable values:
//  a string (even if empty) to set the search param
//  null or undefined to unset the search param
export function useSearchParam(name) {
  const history = useHistory();
  const [value, setValue] = useState(
    new URLSearchParams(history.location.search).get(name) ?? ''
  );
  useEffect(() => {
    return history.listen((change) => {
      const newValue = new URLSearchParams(change.search).get(name);
      if (value !== newValue) {
        setValue(newValue ?? '');
      }
    });
  }, [name]);
  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (
      value === null ||
      value === undefined ||
      value === false ||
      value === ''
    ) {
      search.delete(name);
    } else if (typeof value === 'string' || value === true) {
      search.set(name, value);
    } else {
      throw new Error('Invalid value for search param');
    }
    history.replace({ ...history.location, search: search.toString() });
  }, [value]);
  return [value, setValue];
}

export default useSearchParam;
