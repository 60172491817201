import React from 'react';
import { PropTypes } from 'prop-types';

// Style for empty rows
const EmptyMarker = ({ children }) => {
  return (
    <span
      style={{
        color: '#BBB',
        fontStyle: 'italic',
        whiteSpace: 'nowrap',
        userSelect: 'none'
      }}
    >
      {' '}
      &lt;{children}&gt;{' '}
    </span>
  );
};

const Field = ({ value }) => {
  if (value === null) {
    return <EmptyMarker>null</EmptyMarker>;
  }
  if (value === undefined) {
    return <EmptyMarker>undefined</EmptyMarker>;
  }
  if (value === '') {
    return <EmptyMarker>empty</EmptyMarker>;
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return <span style={{ whiteSpace: 'nowrap' }}>{value}</span>;
};

// Show value pretending is an array of objects
const TableView = ({ value }) => {
  try {
    value = JSON.parse(value);
  } catch {}
  if (!Array.isArray(value)) {
    return 'Not an array';
  }
  if (value.length === 0) {
    return 'Empty array';
  }
  const fields = Object.keys(value[0]);
  return (
    <table style={{ borderSpacing: '10px 2px' }}>
      <thead
        style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}
      >
        <tr>
          {fields.map((field) => (
            <th key={field}>{field}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {value.map((row) => (
          <tr>
            {fields.map((field, i) => (
              <td key={i}>
                <Field value={row[field]} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TableView.propTypes = {
  value: PropTypes.any
};

export default TableView;
