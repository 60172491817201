import React from 'react';
import JobList from '../JobList';

// TODO: Find where to place all the commmon constants or types/ This type `RETIRED` is
// TODO: already in Charlotte-jobs:
const RETIRED = 'retired';
const ADMIN = 'admin';

const filter = ({ status, jobName }) => {
  const isActive = status !== RETIRED;
  const isAdmin = jobName.toLowerCase().includes(ADMIN);
  return isActive && isAdmin;
};

const AdminJobs = (props) => {
  return <JobList {...props} filter={filter} />;
};

export default AdminJobs;
