import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4)
}));

const Content = styled('div')({
  paddingTop: 150,
  textAlign: 'center'
});

const Image = styled('img')({
  marginTop: 50,
  display: 'inline-block',
  maxWidth: '100%',
  width: 560
});

const NotFound = () => {
  return (
    <Root>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <Content>
            <Typography variant="h1">
              404: The page you are looking for isn’t here
            </Typography>
            <Typography variant="subtitle2">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </Typography>
            <Image
              alt="Under development"
              src="/images/undraw_page_not_found_su7k.svg"
            />
          </Content>
        </Grid>
      </Grid>
    </Root>
  );
};

export default NotFound;
