import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { indexOf } from 'ramda';
import { Button, Modal, styled } from '@mui/material';
import CreateIntegration from '../CreateIntegration';
import {
  deleteIntegration,
  createIntegration
} from '../../../../_services/ApiService';
import { SearchInput } from 'components';
import ConfirmButton from 'components/ConfirmButton';

const Row = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  columnGap: theme.spacing(1)
}));

const Spacer = styled('span')({
  flexGrow: 1
});

const IntegrationSearchInput = styled(SearchInput)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const ModalContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 600,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.palette.shadows.primary,
  padding: theme.spacing(4, 4, 4),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

const IntegrationsToolbar = (props) => {
  const {
    className,
    integrations,
    setIntegrations,
    selectedIntegrations,
    setSelectedIntegrations,
    ...rest
  } = props;

  const [state, setState] = useState({
    deleteDialogOpen: false,
    createIntegrationOpen: false,
    values: {
      _id: '',
      companyName: '',
      integrationId: '',
      eventType: '',
      emailEnabled: false,
      emailRecipient: '',
      config: '{ }',
      source: '{ }',
      createdDate: '',
      updatedDate: ''
    },
    errors: {},
    errorMessage: ''
  });

  const toggleCreateIntegration = () => {
    setState({
      ...state,
      createIntegrationOpen: !state.createIntegrationOpen
    });
  };

  const deleteSelected = async () => {
    try {
      await Promise.all(selectedIntegrations.map(deleteIntegration));
      setIntegrations([
        ...integrations.filter(
          (integration) => indexOf(integration._id, selectedIntegrations) === -1
        )
      ]);
      setSelectedIntegrations([]);
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmitIntegration = async (event) => {
    event.preventDefault();
    if (Object.values(state.errors).some((e) => e === true)) return;

    const values = {
      companyName: event.target.elements.companyName.value,
      integrationId: event.target.elements[2].value,
      eventType: event.target.elements.eventType.value,
      emailRecipient: event.target.elements[6].value,
      emailEnabled: event.target.elements[8].checked,
      config: JSON.parse(event.target.elements.config.value),
      source: JSON.parse(event.target.elements.source.value)
    };

    try {
      const newIntegration = await createIntegration(values);
      setIntegrations([...integrations, newIntegration]);
      setState({
        deleteDialogOpen: false,
        createIntegrationOpen: false,
        values: {
          _id: '',
          companyName: '',
          integrationId: '',
          eventType: '',
          emailEnabled: false,
          emailRecipient: '',
          config: '{ }',
          source: '{ }',
          createdDate: '',
          updatedDate: ''
        },
        errors: {},
        errorMessage: ''
      });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        errorMessage: err.message
      });
    }
  };

  return (
    <div {...rest}>
      <Row>
        <Spacer />
        <ConfirmButton
          acceptButtonText="Delete"
          dialogBody="Are you sure you want to delete the selected Integrations?"
          dialogTitle="Delete Integrations?"
          disabled={!selectedIntegrations.length}
          onConfirm={deleteSelected}
          openDialogButtonText="Delete"
        />
        <Button
          color="primary"
          onClick={toggleCreateIntegration}
          variant="contained"
        >
          Add Integration
        </Button>
        <Modal
          onClose={toggleCreateIntegration}
          open={state.createIntegrationOpen}
        >
          <ModalContainer>
            <CreateIntegration
              formState={state}
              isUpdate={false}
              onSubmitIntegration={onSubmitIntegration}
              setFormState={setState}
            />
          </ModalContainer>
        </Modal>
      </Row>
      <Row>
        <IntegrationSearchInput placeholder="Search Integration" />
      </Row>
    </div>
  );
};

IntegrationsToolbar.propTypes = {
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      companyName: PropTypes.string,
      integrationId: PropTypes.string,
      eventType: PropTypes.string,
      emailEnabled: PropTypes.bool,
      emailRecipient: PropTypes.string,
      config: PropTypes.object,
      source: PropTypes.object,
      createdDate: PropTypes.string,
      updatedDate: PropTypes.string
    })
  ),
  selectedIntegrations: PropTypes.arrayOf(PropTypes.string),
  setIntegrations: PropTypes.func,
  setSelectedIntegrations: PropTypes.func
};

export default IntegrationsToolbar;
