import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreateIntegration from '../CreateIntegration';
import EventsTable from '../EventsTable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import {
  updateIntegration,
  getIntegration,
  runIntegration
} from '../../../../_services/ApiService';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Modal,
  Snackbar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  styled
} from '@mui/material';
import { ActionCompletedSnackbar } from 'components/ActionCompletedSnackbar';

const ModalContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.palette.shadows.primary,
  padding: theme.spacing(4, 4, 4),
  width: '85%',
  height: '90%',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

const Footer = styled(CardActions)({
  justifyContent: 'flex-end'
});

const Content = styled(CardContent)({
  padding: 0
});

const Inner = styled('div')({
  minWidth: 1050
});

const IntegrationsTable = (props) => {
  const {
    className,
    integrations,
    setIntegrations,
    selectedIntegrations,
    setSelectedIntegrations,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageCount,
    ...rest
  } = props;

  const [state, setState] = useState({
    updateIntegrationOpen: false,
    values: {
      _id: '',
      companyName: '',
      companyId: '',
      eventType: '',
      emailEnabled: false,
      emailRecipient: '',
      config: '{ }',
      source: '{ }',
      createdDate: '',
      updatedDate: ''
    },
    errors: {},
    errorMessage: ''
  });
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleSelectAll = (event) => {
    const { integrations } = props;

    let selectedIntegrations;

    if (event.target.checked) {
      selectedIntegrations = integrations.map((integration) => integration._id);
    } else {
      selectedIntegrations = [];
    }
    setSelectedIntegrations(selectedIntegrations);
  };

  const closeUpdateIntegration = () => {
    setState({
      updateIntegrationOpen: false,
      values: {
        _id: '',
        companyName: '',
        companyId: '',
        eventType: '',
        emailEnabled: false,
        emailRecipient: '',
        config: '{ }',
        source: '{ }',
        createdDate: '',
        updatedDate: ''
      },
      errors: {},
      errorMessage: ''
    });
  };

  const openUpdateIntegration = async (integrationId) => {
    try {
      const integration = await getIntegration(integrationId);
      const newIntegration = {
        ...integration,
        integrationId: integrationId,
        config: JSON.stringify(integration.config, null, 2),
        source: JSON.stringify(integration.source, null, 2)
      };
      setState({
        ...state,
        updateIntegrationOpen: true,
        values: newIntegration
      });
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        errorMessage: err.message
      });
      setShowSnackbar(true);
    }
  };

  const onUpdateIntegration = async (event) => {
    event.preventDefault();
    if (Object.values(state.errors).some((x) => x === true)) {
      return;
    }

    try {
      const saveIntegration = {
        ...state.values,
        config: JSON.parse(state.values.config ? state.values.config : '{ }'),
        source: JSON.parse(state.values.source ? state.values.source : '{ }')
      };
      await updateIntegration(state.values._id, saveIntegration);
      const updateIndex = integrations.findIndex(
        (x) => x._id === state.values._id
      );
      const updateCopy = [...integrations];
      updateCopy[updateIndex] = {
        ...updateCopy[updateIndex],
        ...saveIntegration
      };
      setIntegrations(updateCopy);
      closeUpdateIntegration();
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        errorMessage: err.message
      });
    }
    setShowSnackbar(true);
  };

  const handleSelectOne = (_, id) => {
    const selectedIndex = selectedIntegrations.indexOf(id);
    let newSelectedIntegrations = [];

    if (selectedIndex === -1) {
      newSelectedIntegrations = newSelectedIntegrations.concat(
        selectedIntegrations,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedIntegrations = newSelectedIntegrations.concat(
        selectedIntegrations.slice(1)
      );
    } else if (selectedIndex === selectedIntegrations.length - 1) {
      newSelectedIntegrations = newSelectedIntegrations.concat(
        selectedIntegrations.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedIntegrations = newSelectedIntegrations.concat(
        selectedIntegrations.slice(0, selectedIndex),
        selectedIntegrations.slice(selectedIndex + 1)
      );
    }

    setSelectedIntegrations(newSelectedIntegrations);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleRunIntegration = async (integrationId) => {
    setState({
      ...state,
      integrationId: integrationId,
      runDialogOpen: !state.runDialogOpen
    });

    try {
      await runIntegration(integrationId);
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        errorMessage: err.message
      });
    }
  };

  const toggleRunDialog = (integrationId) => {
    setState({
      ...state,
      integrationId: integrationId,
      runDialogOpen: !state.runDialogOpen
    });
  };

  return (
    <Card {...rest}>
      <Content>
        <PerfectScrollbar>
          <Inner>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedIntegrations.length === integrations.length
                      }
                      color="primary"
                      indeterminate={
                        selectedIntegrations.length > 0 &&
                        selectedIntegrations.length < integrations.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Integration ID</TableCell>
                  <TableCell>Integration Type</TableCell>
                  <TableCell>Run</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrations.map((integration) => (
                  <TableRow
                    hover
                    key={integration._id}
                    selected={
                      selectedIntegrations.indexOf(integration._id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedIntegrations.indexOf(integration._id) !== -1
                        }
                        color="primary"
                        onChange={(event) =>
                          handleSelectOne(event, integration._id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{integration.companyName}</TableCell>
                    <TableCell>{integration._id}</TableCell>
                    <TableCell>{integration.eventType}</TableCell>
                    <TableCell>
                      {integration.runnable && (
                        <IconButton
                          aria-label="run"
                          onClick={() => toggleRunDialog(integration._id)}
                        >
                          <PlayCircleFilled />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => openUpdateIntegration(integration._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Modal
              onClose={closeUpdateIntegration}
              open={state.updateIntegrationOpen}
            >
              <ModalContainer>
                <CreateIntegration
                  formState={state}
                  isUpdate
                  onSubmitIntegration={onUpdateIntegration}
                  setFormState={setState}
                />
                <EventsTable
                  formState={state}
                  integrationId={state.values._id}
                  setFormState={setState}
                  setShowSnackbar={setShowSnackbar}
                />
              </ModalContainer>
            </Modal>
            <Dialog
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              onClose={toggleRunDialog}
              open={state.runDialogOpen}
            >
              <DialogTitle id="alert-dialog-title">
                {'Run Integration?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to run the selected Integration?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={toggleRunDialog}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={() => handleRunIntegration(state.integrationId)}
                >
                  Run
                </Button>
              </DialogActions>
            </Dialog>
          </Inner>
        </PerfectScrollbar>
      </Content>
      <Footer>
        <TablePagination
          component="div"
          count={pageCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Footer>
      <ActionCompletedSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        onExited={() => setState(state => ({...state, errorMessage: ''}))}
        errorMessage={state.errorMessage}
      />
    </Card>
  );
};

IntegrationsTable.propTypes = {
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      companyName: PropTypes.string,
      companyId: PropTypes.string,
      eventType: PropTypes.string,
      runnable: PropTypes.bool,
      config: PropTypes.object,
      source: PropTypes.object,
      createdDate: PropTypes.string,
      updatedDate: PropTypes.string
    })
  ),
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  selectedIntegrations: PropTypes.arrayOf(PropTypes.string),
  setIntegrations: PropTypes.func,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSelectedIntegrations: PropTypes.func
};

export default IntegrationsTable;
