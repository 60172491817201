import React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useSearchParam } from 'helpers/useSearchParam';
import { useJobsContext } from 'components/JobList';

export default function SelectCompany({}) {
  const { onCompanyChange, companies } = useJobsContext();
  const onChange = (event) => onCompanyChange(event.target.value);

  const [company] = useSearchParam('company');

  return (
    <Box sx={{ minWidth: 105 }}>
      <FormControl fullWidth>
        <InputLabel id="select-company-label">Company</InputLabel>
        <Select
          color="secondary"
          id="Company-select"
          label="Company"
          labelId="select-company-label"
          onChange={onChange}
          value={company}
        >
          <MenuItem value="All">All Companies</MenuItem>
          {companies?.map((company, index) => (
            <MenuItem key={index} value={company || 'no-company'}>
              {company || 'No company in job'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
